.cart-message {
	border-bottom: 1px solid $gray;
	div {
		width: 1160px;
		margin: 0 auto;
		text-transform: uppercase;
		color: #61656E;
		padding: 12px 0;
	}
}
.cart {
	.no-items {
		text-align: center;
		h2 {
			border: 0;
		}
		.button {
			margin: 60px 0;
			&:before {
				content: '';
				display: none;
			}
		}
	}
	.columns.title {
		width: 42.76316%;
		padding-left: 32px;
	}
	.columns.quantity {
		width: 28.61842%;
		padding-left: 32px;
	}
	.columns.subtotal {
		width: 28.61842%;
		padding-left: 32px;
	}
	.cart-header {
		width: 100%;
		float: left;
		font-size: 14px;
		text-transform: uppercase;
		line-height: 56px;
		height: 56px;
		border-bottom: 1px solid $gray;
		color: $gold;
		.wrapper {
			width: 1216px;
		}
	}
	.error-message {
		color: #cc0000;
	  	text-transform: uppercase;
	  	font-size: 13px;
	  	padding-top: 12px;
	}
	.cart-products {
		.cart-product {
			padding: 32px 0;
			border-bottom: 1px solid $gray;
			h2 {
				font-size: 18px;
				border: 0;
				padding: 0;
			}
			.color-size {
				font-size: 14px;
				color: $gold;
				text-transform: uppercase;
			}
			img.product-image {
				width: 100%;
				height: auto;
				float:left;

			}
			.product-details {
				width: 100%;
				float: left;
				padding-top: 12px;
			}
			.quantity-control.down {
				position: relative;
  				cursor: pointer;
  				width: 19px;
  				display: inline-block;
  				top: -8px;
  				margin-left: 28px;
  				&:after {
  					content: '';
					position: absolute;
					top: 25%;
					left: 9px;
					border-top: 7px solid #fff;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
  				}
  				&:before {
  					content: '';
					position: absolute;
					top: 25%;
					left: 8px;
					border-top: 8px solid $darkGrayText;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
  				}
  				&:hover:before {
					border-top-color: $black;
				}
			}
			.quantity-control.up {
				position: relative;
  				cursor: pointer;
  				width: 19px;
  				display: inline-block;
  				margin-left: 4px;
  				&:after {
  					content: '';
					position: absolute;
					bottom: 25%;
					left: 9px;
					border-bottom: 7px solid #fff;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
  				}
  				&:before {
  					content: '';
					position: absolute;
					bottom: 25%;
					left: 8px;
					border-bottom: 8px solid $darkGrayText;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
  				}
  				&:hover:before {
					border-bottom-color: $black;
				}
			}
			.remove-cart-item {
				float: right;
				margin-right: 30px;
			}
		}
	}
	.promo-shipping-tax, .total-calculation, .cart-total {
		height: 180px;
		border-bottom: 1px solid $gray;
		color: #61656E;
	}
	.promo-shipping-tax {
		padding: 34px 38px 0 38px;
		border-right: 1px solid $gray;
		h3 {
			font-size: 15px;
			text-transform: uppercase;
			margin-bottom: 38px;
		}
		input[type="text"] {
			border: 1px solid $gray;
			height: 50px;
			line-height: 48px;
			padding: 0 18px;
			@include secondary-font;
			font-size: 15px;
			color: #61656E;
			width: 320px;
		}
		.button {
			border: 1px solid $grayText;
			height: 50px;
			line-height: 48px;
			text-transform: uppercase;
			font-size: 15px;
			padding: 0 68px;
			display: inline-block;
			border-radius: 25px;
			background: $white;
			@include transition(color 0.15s ease-out, background-color 0.15s ease-out);
			text-decoration: none;
			margin-left: 12px;
			&:hover {
				border: 0;
				color: $white;
				background-color: $gold;
				line-height: 50px;
			}
		}
	}
	.total-calculation, .cart-total {
		text-transform: uppercase;
		font-size: 18px;
		font-weight: bold;
		line-height: 180px;
		span {
			font-weight: 400;
			color: $gold;
			display: inline-block;
			float: right;
		}
		div {
			padding: 0 28px;
			height: 60px;
			line-height: 60px;
			border-bottom: 1px solid $gray;
			&:last-child {
				border-bottom: 0;
			}
		}
		.style-select{
			    border: 0;
			    width: auto;
			    overflow: hidden;
			    position: relative;
			    display: inline-block;
			    font-size: 14px;
			    color: $gold;
			    font-weight: normal;
			    line-height: 30px;
			    top: 10px;
			    height: auto;
			    padding-left: 12px;
			    &:before {
			    	content: '';
			    	display: block;
			    	width: 12px;
			    	height: 9px;
			    	position: absolute;
			    	top: 10px;
			    	right: 38px;
			    	background: url("../images/icon-select.png") no-repeat;
			    }
			    select {
				    padding: 0 38px 0 12px;
				    border: none;
				    box-shadow: none;
				    background: transparent;
				    background-image: none;
				    -webkit-appearance: none;
				    &:focus {
				    	outline: 0;
				    }
				}
			}
	}
	.cart-total {
		padding: 0 28px;
	}
	.cart-actions {
		padding: 36px;
		a.continue {
			text-transform: uppercase;
			font-size: 15px;
			color: #61656E;
			border-bottom: 1px solid #61656E;
			line-height: 30px;
			display: inline-block;
			@include transition(color 0.15s ease-out, border-color 0.15s ease-out);
			margin-top: 10px;
			&.display-for-small-only {
				display: none;
			}
			&:hover {
				text-decoration: none;
				color: $black;
				border-color: $black;
			}
		}
		a.button.gold {
			float: right;
			&:before {
				content: '';
				display: none;
			}
		}
	}
}
@include queryWidth(max, 1400px) {
	.cart {
		.textured-holder {
			.inner {
				width: 100%;
				border: 0;
			}
		}
		.cart-header {
			.wrapper {
				width: 100%;
			}
		}

		.promo-shipping-tax {
			padding: 34px 28px 0 28px;
			.button {
				margin: 20px 0;
			}
			&.hide-for-small {
				height: 80px;
			}
			input[type="text"] {
				max-width: 100%;
			}
		}
		.promo-shipping-tax, .total-calculation {
			height: 232px;
		}
		.cart-total {
			height: 80px;
			line-height: 80px;
		}
	}
	.cart-message div {
		padding-left: 28px;
	}
}

@include mobile {
	.cart {
		.cart-total, .total-calculation {
			height: auto;
		}
		.total-calculation {
			border-bottom: 0;
			height: auto;
		}
		.promo-shipping-tax {
			height: auto;
			border-right: 0;
			padding: 28px;
			input[type="text"] {
				max-width: 100%;
			}
			.button{
				display: block;
				margin: 20px 0;
			}
		}
		.cart-actions {
			a.continue.hide-for-small {
				display: none;
			}
			a.button.gold {
				float: left;
				margin-bottom: 12px;
			}
		}
		.columns.title {
			width: 100%;
			padding: 0 28px;
		}
		.cart-header {
			display: none;
		}
		.mobile-column-header {
			font-size: 14px;
		    text-transform: uppercase;
		    color: #8b8046;
		    margin: 12px 0;
		}
		.columns.quantity, .columns.subtotal {
			width: 50%;
		}
	}
	
}