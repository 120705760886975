.holiday-popup-overlay {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.23);
	z-index: 9999;
	@include transition(opacity 0.2s ease, visibility 0.2s ease);
	&.closed {
		opacity: 0;
		visibility: hidden;
	}
	&.temp-dallas-overlay {
		.holiday-popup {
			h2 {
				font-size: 38px;
			}
		}
	}
	.holiday-popup {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 732px;
		background: $white;
		padding: 32px 0 26px;
		transform: translate(-50%, -50%);
		max-width: calc(100% - 40px);
		.close-popup {
			width: 28px;
			height: 28px;
			border: 1px solid rgba(178, 174, 165, 0.3);
			border-radius: 50%;
			position: absolute;
			top: 12px;
			right: 12px;
			cursor: pointer;
			&:before, &:after {
				content: '';
				display: block;
				width: 15px;
				height: 1px;
				background: $gold;
				position: absolute;
				top: 13px;
				left: 5px;
			}
			&:before {
				@include transform(rotate(-45deg));
			}
			&:after {
				@include transform(rotate(45deg));
			}
		}
		.icon {
			text-align: center;
			img {
				width: 24px;
				height: 24px;
			}
		}
		h2 {
			font-size: 46px;
			line-height: 58px;
			font-weight: bold;
			color: #61656e;
			text-align: center;
			text-transform: uppercase;
			margin: 5px 0 16px;
			padding: 0 32px;
		}
		.divider {
			height: 1px;
			background: rgba(178, 174, 165, 0.3);
		}
		.main-content {
			padding: 0 32px;
			@include secondary-font;
			text-align: center;
			font-size: 18px;
			line-height: 29px;
			color: $darkGrayText;
			br {
				display: none;
			}
			.box-content {
				color: $white;
				@include primary-font;
				font-size: 14px;
				text-transform: uppercase;
				background: $gold;
				max-width: 432px;
				min-height: 50px;
				margin: 0 auto;
				line-height: 20px;
				padding: 15px 20px;
			}
			p {
				&:first-child {
					margin-top: 26px;
					margin-bottom: 10px;
				}
				&:last-child {
					margin-top: 8px;
					margin-bottom: 28px;
				}
			}
		}
		.lower-content {
			font-weight: bold;
			color: $gold;
			font-size: 14px;
			line-height: 17px;
			text-align: center;
			text-transform: uppercase;
			padding-top: 15px;
			br {
				display: none;
			}
			p {
				margin-bottom: 0
			}
			span {
				color: #61656e;
			}
		}
	}
}
@include mobile {
	.holiday-popup-overlay {

		&.temp-dallas-overlay {
			.holiday-popup {
				h2 {
					font-size: 32px;
					line-height: 38px;
				}
			}
		}
		.holiday-popup {
			h2 {
				font-size: 32px;
				line-height: 38px;
			}
			.main-content {
				padding: 0 20px;
				font-size: 16px;
				.box-content {
					font-size: 12px;
					padding: 15px 10px;
				}
			}
			.lower-content {
				padding: 0 10px;
			}
		}
	}
}
@include queryWidth(max, 380px) {
	.holiday-popup-overlay {
		.holiday-popup {
			.main-content {
				br {
					display: block;
				}
			}
			.lower-content {
				padding: 0 10px;
				br {
					display: block;
				}
			}
		}
	}
}