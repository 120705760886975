$pseudo-phprefix: "::-webkit-input-placeholder" "::-moz-placeholder" ":-ms-input-placeholder" "::placeholder";

@mixin placeholder {
  @each $pseudo in $pseudo-phprefix {

    @at-root #{&}#{$pseudo} {
      @content
    }

  }
}

.rdw-button{
	background: $gold;
	color:$white;
	font-weight:700;
	min-width:145px;
	padding:0 20px;
	height: 50px;
	line-height:50px;
	border-radius: 25px;
	text-transform: uppercase;
	text-align: center;
	font-size: 15px;
	display: inline-block;
	float:left;
}

#showroom-header {
    width: 100%;
    height: 472px;
    color: $white;
    position: relative;
    overflow:auto;
    .opacity-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
    }
    .showroom-header-background {	
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: center center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    h1 {
    	font-weight: 700;
    	font-size: 44px;
    	margin: 183px 0 0 0px;
    	text-transform: uppercase;
    	line-height: 60px;
        position: relative;
    }
	.location{
		position:relative;
		font-size: 18px;
		line-height: 38px;
		font-weight: 700;
		display: inline-block;
		&:before {
			content: ' ';
			background: url(../images/sprite.png) no-repeat -1px -1px;
		    width: 11px;
		    height: 16px;
		    display: inline-block;
		    margin-right: 4px;
		    position: relative;
		    top: 2px;
		}
		&.remote {
			&:before {
				display: none;
			}
		}
	}
	a.down-arrow{
		position:relative;
		display:block;
		width: 56px;
		height: 56px;
		margin:	80px 0 0 0px;
		background: url(../images/sprite.png) no-repeat -108px -113px;
		display: none;
	}
	h1, .location, a.down-arrow{
		margin-left: calc(50% - 608px);
	}

}
.hours-tour-row-holder, .projects-team-holder, .showroom-project-gallery-holder, .remote-team-holder, .showroom-contact-form-holder {
	width: 100%;
	float: left;
	background: url(../images/texture-bg.jpg) repeat;
}
.hours-row, .tour-row, .location-clients-row, .projects-row, .showroom-project-gallery-row, .remote-team-row, .flex-team-row, .showroom-contact-form-row {
	width: 1216px;
	background: $white;
	margin: 0 auto;
	border-top: 1px solid $gray;
	border-left: 1px solid $gray;
	border-right: 1px solid $gray;
	overflow:auto;
	&.remote{
		border-top:none;
	}
}
.hours-row, .projects-row{
	.hours-box{
		padding:40px 40px 20px 60px;
		border-right: 1px solid $gray;
		.separator{
			border-top:1px solid $gold;
			margin:20px 0;
		}
	}
	h3, h4, .hours {
		font-weight: 500;
		font-size: 18px;
		color: $gold;
		position: relative;
		.gray, &.gray{
			color:$grayText;
		}
		&.phone, &.email{
			font-size:16px;	
			margin-top:15px;
		}
		&.phone{
			margin-top:35px;
		}
		&.hours {
			color:$grayText;
			strong {
				color: $gold;
			}
		}
		&.phone:before{
			content: ' ';
		    display: inline-block;
		    position: relative;
		    top:3px;
		    width: 13px;
		    height: 18px;
		    margin-left:3px;
		    background: url(../images/sprite.png) no-repeat -65px -106px;
		    margin-right: 3px;
		}
		&.email:before{
			content: ' ';
			display: inline-block;
		    position: relative;
		    top:3px;
		    width: 17px;
		    height: 14px;
		    background: url(../images/sprite.png) no-repeat -65px -124px;
		    margin-right: 0px;
		}
	}
	a.contact-button{
		margin:45px 0 70px 0;
		border: 0;
		background: $gold;
		color:$white;
		font-weight:700;
		height: 50px;
		line-height:50px;
		border-radius: 25px;
		text-transform: uppercase;
		width: 100%;
		text-align: center;
		font-size: 15px;
		display: inline-block;
		float:left;
		&:hover {
			text-decoration: none;
			color: $grayText;
			border: 1px solid $grayText;
			background-color: $white;
			font-weight: 700;
		}
	}
	.map-box{
		iframe {
		    width: 90%;
		    margin: 0 auto;
		    margin-top: 30px;
		    max-height: 425px;
		    display: block;
		}
	}
}
.location-clients-row {
	display: flex;
}
.tour-row, .location-clients-row{
	.border-left{border-left:1px solid $gray;}
	overflow-x: hidden;
	h3, h4 {
		font-weight: 700;
		font-size: 20px;
		text-transform: capitalize;
		color: $grayText;
		border-bottom: 1px solid $gray;	
		padding: 68px 0 22px 38px;
		position: relative;
	}
	iframe {
		height:635px;
		width: 100%;
		float: left;
	}
	.clients{
		.clients-holder{
			padding:25px 15px 35px 15px;
			text-align:center;
				img{
				width:23.33333333%;
				margin:4.5%;
				margin-bottom:35px;
			}
		}
	}
	.partners-holder{
		padding:50px 20px 40px 50px;
		.partner{
			display:block;
			margin-bottom:35px;
			h4{
				color:$gold;
				padding:0;
				font-size:16px;
				border-bottom:none;
				font-weight:400;
				span.company-type {
					color:$grayText;
					&:before {
						content: ' | ';
					}
				}
			}
			p{
				color:$darkGrayText;
				@include secondary-font;
				font-size:16px;
				margin: 6px 0;
			}
		}

	}

}
	
.showroom-testimonials-holder{ 

    background-size: cover;
    max-width: 100%;
    padding: 0 20px;
    .testimonials-row {
    	max-width: 100%;
    }
}

.showroom-project-gallery-holder{
	.showroom-project-gallery-row{
		overflow-x: hidden;
		h3, h4 {
			font-weight: 700;
			font-size: 20px;
			text-transform: uppercase;
			color: $grayText;
			border-bottom: 1px solid $gray;	
			padding: 68px 0 22px 38px;
			position: relative;
		}
		.projects{
			position:relative;
			height:635px;
			width:102%;
			#showroom-project-backgrounds {
		        position: absolute;
		        left: 0;
		        top: 0;
		        width: 100%;
		        height: 100%;
		        div.showroom-project-background {
		            background-repeat: no-repeat;
		            background-size: cover;
		            background-position: center center;
		            position: absolute;
		            left: 0;
		            top: 0;
		            width: 100%;
		            height: 100%; 
		            opacity: 0;
		            &:first-child {
		                opacity: 1;
		            }
	        	}
			}
		}
		.showroom-project-navigation{
			display:flex;
			flex-flow:row wrap;
			height:84px;
			border-bottom:1px solid $gray;
			.arrows-holder{
				flex:0 1 9%;
				border-right:1px solid $gray;
				text-align:center;
				.arrow{
					display:inline-block;
					width:9px;
					height:15px;
					cursor:pointer;
					margin: 34px 0 31px 0;
					background: url(../images/sprite.png) no-repeat;
					&.left-arrow{
						background-position: -80px -115px;
						margin-right:26px;
					}
					&.right-arrow{
						background-position: -91px -115px;
					}
				}
			}
			.project-descriptions{
				flex: 0 1 76%;
				height:84px;
				border-right:1px solid $gray;
				.showroom-project-description{
					opacity:0;
					position:absolute;
					top:34px;
					left:31px;
					font-size:14px;
					color:$grayText;
					span{color:$gold;}
					&:first-child{
						opacity:1;
					}
				}
			}
			.inspiration{
				flex:0 1 15%;
				height:84px;
				a{ 
					padding:34px 0 0 0px;
					text-align:center; 
					display:block;
					font-size:12px; 
					color:$grayText; 
					text-decoration:underline;
				}
			}


		}
	}
}

.clients-row-holder.team-holder {
	.clients-row {
		border-top: 1px solid $gray;
	}
}

.clients-holder{
	.team-member .overlay {  
		background:rgba(68,68,68,.9);
		text-align:left;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		opacity:0;
		-webkit-transition: opacity .25s ease;
		font-size:20px;
		text-transform:uppercase;
		color:$white;
		.team-member-description{	
			margin:35% 0 0% 10%;
			width:80%;
			.name{
				border-bottom:1px solid $gold;
				padding-bottom:15px;
			}
			.title {
				@include secondary-font;
				font-size:15px;
				padding-top:15px;
			}
			.email {
				@include secondary-font;
				padding-top: 8px;
				text-transform: none;
				font-size: 12px;
			}


		}
   }
	.team-member:hover .overlay{
		opacity:1;
	}
}

.flex-team-holder{
	.flex-team-row{
		padding-bottom:30px;
		overflow:hidden;
		h3{
			font-weight: 700;
			font-size: 20px;
			text-transform: uppercase;
			color: $grayText;
			border-bottom: 1px solid $gray;	
			padding: 68px 0 22px 38px;
			position: relative;
		}
		.flex-team-box{
			width:100%;
			margin-top:66px;
			float:left;
			display: flex;
			flex-flow:row wrap;
			justify-content:center;
			align-content:center;
			.flex-team-image-holder{
				flex: 0 1 31%;
				display: flex;
				flex-flow: row wrap;
				justify-content:center;
				.flex-team-image{
					flex: 0 1 100%;
					order:0;
				}
			}
			.flex-team-description-holder{
				flex:0 1 55%;
				display: flex;
				flex-flow: row wrap;
				justify-content:left;
				.flex-name-title{
					order:1;
					flex:0 1 100%;
					max-height:80px;
					.name{
						padding-top:13px;
						font-size:20px;
						font-weight:700;
						text-transform:uppercase;
						color:$grayText;
						margin-bottom:14px;
					}
					.title{
						font-size:16px;
						text-transform:uppercase;
						color:$gold;
						margin-bottom:21px;
					}
				}
				.flex-text{
					order: 2;
					flex: 0 1 100%;
					@include secondary-font;
					font-size:16px;
					color:$grayText;
					line-height: 24px;
					border-bottom:1px solid $gold;
					border-top:1px solid $gold;
					margin:0;
					padding: 26px 0;
				}
				.flex-phone-email{
					order: 4;
					flex:0 1 70%;
					margin-top:37px;
					align-self:center;
					h3{
						&.phone, &.email{
							font-size:16px;	
							padding:0;
							border:none;
							margin-left:35px;
						}
						&.phone{
							margin-bottom:10px;
						}
						&.phone:before{
							content: ' ';
						    display: inline-block;
						    position: relative;
						    top:3px;
						    width: 13px;
						    height: 18px;
						    margin-left:3px;
						    background: url(../images/sprite.png) no-repeat -65px -106px;
						    margin-right: 3px;
						}
						&.email:before{
							content: ' ';
							display: inline-block;
						    position: relative;
						    top:3px;
						    width: 18px;
						    height: 14px;
						    background: url(../images/sprite.png) no-repeat -65px -124px;
						    margin-right: 0px;
						}
					}
				}
				.flex-contact{
					order:3;
					flex: 0 1 30%;
					margin-top:37px;
					a{
						@extend .rdw-button;
					}
				}
			}
		}
		.flex-text-medium{
			display:none;
		}
	}
}

.input{
	@include secondary-font;
	font-size:15px;
	width:46%;
	margin:15px;
	display: inline-block;
	border:1px solid $gray;
	padding:11px 20px 19px 20px;
	@include placeholder { 
		@include secondary-font;
		color: $black; 
		font-size:15px;
		line-height:15px;
	}
	&:focus{
		outline:none;
		background:$grayBackground;
		color:#fff;
		padding:11px 19px 14px 19px;
		border:1px solid $grayBackground;
		border-bottom:6px solid $gold;
		@include placeholder { 
			color:$white;
			margin:0;
			padding:0;
			border-left:1px solid $white;
			padding-left:6px;
			font-weight:700;
		}
	}
}


.showroom-contact-form-row{
	h3 {
		font-weight: 700;
		font-size: 20px;
		text-transform: uppercase;
		color: $grayText;
		border-bottom: 1px solid $gray;	
		padding: 68px 0 22px 38px;
		position: relative;
		margin-bottom:27px;
	}
}



@include queryWidth(max, 1400px) {
	.hours-row, .tour-row, .location-clients-row, .projects-row, .showroom-project-gallery-row, .remote-team-row, .flex-team-row, .showroom-contact-form-row{
		width:90%;
	}
	.clients-holder .team-member .overlay{
		height:98.8%;
	}
	#showroom-header{
		h1, .location, a.down-arrow{
			margin-left:5%;
		}
	}

	.tour-row, .location-clients-row{
		.partners-holder{
			.partner{
				h4{
					span.company-type {
						line-height: normal;
					}
				}
			}
		}
	}
}

@include queryWidth(max, 1310px) {
	.flex-team-holder{
		.flex-team-row{
			.flex-team-box{
				.flex-team-image-holder{
					.flex-team-image{
						img{
						}
					}
				}
				.flex-team-description-holder{
					margin-left:15px;
					.flex-name-title{
					
					}
					.flex-text{

					}
					.flex-phone-email{
						margin-top:0;
						h3.email{font-size:12px;}
						h3.phone{font-size:14px;}
					}
					.flex-contact{
						margin-top:10px;
					}
				}
			}
		}
	}

}

@include queryWidth(max, 1200px) {
	.hours-row{
		a.contact-button{
			margin:20px 0;
		}
		.hours-box{
			padding-left:25px;
		}
		.map-box{
			img{
				max-height:650px;
				margin-bottom:30px;
			}
		}
	}
	.location-clients-row{
		.partners-holder{
			padding:50px 10px 20px 38px;
		}
		.clients-row{
			padding: 35px 15px 15px 15px;
		}
	}
}




@include tablet {
	.location-clients-row {
		display: block;
	}
	.hours-row{
		.large-6{
			width:100%;
			&.hours-box{
				border-bottom:1px solid $gray;
				border-right:none;
			}
		}
	}
	.tour-row{
		.tour{
			height:454px;
			overflow:hidden;
			margin-top:-2px;
			margin-bottom:-2px;
		}
	}
	.location-clients-row{
		&.remote{
			border-top:1px solid $gray;
		}
		.large-6{
			width:100%;
		}
		.clients{
			border-bottom:1px solid $gray;
		}
		.border-left{
			border:none;
		}
		.partners-holder{
			padding-top:60px;
		}
	}
	.showroom-project-gallery-holder{
		.showroom-project-gallery-row{
			height:590px;
			.projects{
				height:306px;
			}
			.showroom-project-navigation{
				.project-descriptions{
					order:0;
					border-right: none;
					flex:0 1 100%;
					.showroom-project-description{
						font-size:12px;
						top:35px;
						left:38px;
					}
				}
				.arrows-holder, .inspiration{
					text-align:center;
					flex:0 1 50%;
				}
				.arrows-holder{
					order:1;
					.arrow.left-arrow{
						margin-left:0;
						margin-right:66px;
					}
				}
				.inspiration{
					order:2;
				}

			}
		}
	}
	.team-holder{
		.clients-row{
			border-top:1px solid $gray;
			.clients-holder{
				padding:24px;
				.client:first-child{
    					min-width: 47%;
    					width: 30.33333%;
					    margin: 1.5%;
					    height: auto;
				}
				.client{
					.overlay{
						display:none;
					}
				}
			}
		}
	}
	.showroom-contact-form-holder{
		.showroom-contact-form-row{
			form{
				input{
					width:90%;
				}
				textarea.message{
					width:90%;
				}
				select{
					width:90%;
				}
			}
		}
	}
	.flex-team-holder{
		.flex-team-row{
			.flex-team-box{
				width:100%;
				margin-top:35px;
				margin-bottom:25px;
				.flex-team-image-holder{
					flex: 0 1 38%;
					.flex-team-image{
						img{
							width:90%;
						}
					}
				}
				.flex-team-description-holder{
					flex: 0 1 53%;
					.flex-name-title{
						order:0;
						flex:0 1 90%;
						.title{
							margin-bottom:-20px;
						}
					}
					.flex-text{
						order:3;
						flex:0 1 90%;
						display:none;
					}
					.flex-phone-email{
						margin-top:10px;
						order:1;
						flex:0 1 90%;
						h3.phone, h3.email{margin-left:0px; margin-top:0; font-size:12px;}
						h3.phone{font-size:14px;}
					}
					.flex-contact{
						order:2;
						flex:0 1 90%;
					}
				}
			}
			.flex-text-medium{
				display:block;
				width:92%;
				@include secondary-font;
				font-size:16px;
				color:$grayText;
				line-height: 24px;
				margin:0 auto;
				padding-bottom:30px;
				border-bottom:1px solid $gold;
			}
		}
		.flex-text-medium:last-child{
			border-bottom:none;
		}
	}
	
}

@include mobile {
	.hours-row, .tour-row, .location-clients-row, .projects-row, .showroom-project-gallery-row, .showroom-contact-form-row, .remote-team-row, .flex-team-row{
		width:100%;
	}
	#showroom-header{
		height:360px;
		h1, .location, a.down-arrow{
			margin-left: 41px;
		}
		h1{
			margin-top:96px;
			font-size:24px;
		}
		.location{
			padding-right:30px;
			font-size:18px;
			line-height:24px;
		}
		a.down-arrow{
			margin-top:38px;
		}
	}
	.hours-tour-row-holder{
		.hours-row{
			padding: 0 30px;
			.hours-box{
				h3{
					font-size:16px;
				}
				h3:first-child{
					font-size:18px;
				}
				h3.phone{
					font-size:14px;
				}
				h3.email{
					font-size:12px;
				}
			}
			.map-box img{
				width:100%;
				margin-top:0; 
				margin-bottom:0;
			}
		}
	}
	.tour-row, .location-clients-row{
		.partners-holder{
			.partner{
				h4{
					line-height: normal;
					span.company-type{
						display: block;
						margin-top: 2px;
						&:before {
							display: none;
						}
					}
				}
			}
		}

	}
	.tour-row{
		padding: 0 30px 30px 30px;
		h3{
			padding: 45px 0 20px 25px;
		}
		.tour{
			max-height:270px;
			min-height:200px;
		}
	}
	.location-clients-row {
		h3, h4{
			padding: 45px 0 20px 25px;
		}
		.clients .clients-holder img{
			width:30%;
			margin-bottom:-9px;
		}
		.partners-holder{
			padding: 45px 0 0px 25px;
			.partner:last-child{
				margin-bottom:33px;
			}
		}
	}
	.showroom-project-gallery-holder {
		.showroom-project-gallery-row{
			height:525px;
			h3{
				padding: 45px 0 20px 25px;
			}
			.showroom-project-navigation {
				height:62px;
				.project-descriptions{
					height:62px;
					.showroom-project-description{
						top:15px;
						left:25px;
						width:90%;
					}
				}
				.arrows-holder{
					height:66px;
					.arrow{
					    margin: 28px 0 0px 0;
					}
				}
				.inspiration{
					 height:66px;
					 a{
				    	padding: 28px 0 0 0px;
					}
				}
			}
		}
	}
	.team-holder{
		.clients-row{
			h3{
				font-size:20px; 
				padding: 45px 0 20px 25px;
			}
			.clients-holder{
				.client{
					margin:0;
					width:100%;
					border-bottom:none;
					padding-top:0;
					img{
						width:100%;
					}
				}
				.client:first-child{
					margin:0;
					width:100%;
					margin-bottom: 22px;
				}
				.client-name{
					padding-left:0;
					width:100%;
					margin-top:25px;
					padding-bottom:22px;
					border-bottom:1px solid $gold;
					margin-bottom:22px;
				}
				.project-description{
					display:block;
					padding-top:0;
				}
			
	
			}
		}
	}
	.flex-team-holder{
		.flex-team-row{
			.flex-team-box{
				width:100%;
				.flex-team-image-holder{
					flex: 0 1 95%;
					text-align:center;
					justify-content:center;
					.flex-team-image{
						width:100%;
					}
				}
				.flex-team-description-holder{
					flex: 0 1 95%;
					justify-content:center;
					margin-left:0;
					border-bottom:1px solid $gold;
					padding-bottom:30px;
					&.last{
						border:none;
					}
					.flex-name-title{
						order:0;
						.title{
							margin-bottom:20px;
						}
					}
					.flex-text{
						display:block;
						order:0;
					}
					.flex-phone-email{
						order:0;
					}
					.flex-contact{
						order:0;
						flex: 0 1 90%;
						margin-top:40px;
						text-align:center;
						a{
							width:100%;
						}
					}
				}
			}
			.flex-text-medium{
				display:none;
			}
		}
	}

}

body{
	overflow-x:hidden;
}