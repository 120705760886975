.content-page-holder {
	.inner {
		padding: 80px 120px;
		color: #424850;
		font-size: 20px;
		@include secondary-font;
		border-bottom: 1px solid $gray;
		h3 {
			padding-top: 12px;
			font-size: 20px;
			text-transform: capitalize;
			color: #61656E;
			@include primary-font;
			margin-bottom: 40px;
		}
		p {
			margin-bottom: 40px;
		}
		a.pdf-download {
			border: 1px dotted $gray;
			font-size: 15px;
			text-transform: uppercase;
			color: $grayBackground;
			height: 46px;
			line-height: 46px;
			display: inline-block;
	        padding: 0 20px;
	        @include primary-font;
			&:before {
				content: ' ';
	            background: url(../images/sprite.png) no-repeat -68px -140px;
	            width: 12px;
	            height: 18px;
	            display: inline-block;
	            margin-right: 6px;
	            position: relative;
	            vertical-align: middle;
			}
		}
		
	}
}
.interior-content {
	padding: 38px 68px;
	color: #424850;
	font-size: 20px;
	@include secondary-font;
	border-bottom: 1px solid $gray;
	&.careers-block {
		border-top: 1px solid $gray;
		border-bottom: 0;
		padding: 38px;
		a {
			text-decoration: underline;
		}
	}
	h3 {
		padding-top: 12px;
		font-size: 20px;
		text-transform: capitalize;
		color: #61656E;
		@include primary-font;
		margin-bottom: 24px;
	}
	p {
		margin-bottom: 24px;
	}
	&.product-category-content {
		padding-top: 24px;
		h1, h2 {
			border: none;
			padding: 0;
			margin: 24px 0;
			font-size: 22px;
		}
		h1 {
			font-size: 26px;
		}
		a {
			font-weight: bold;
		}
	}
}
@include tablet {
	.content-page-holder {
		.inner {
			padding: 40px;
		}
	}
}
@include mobile {
	.content-page-holder {
		.inner {
			padding: 38px 20px;
			font-size: 16px;
			h3 {
				padding-top: 0;
				font-size: 16px;
				margin-bottom: 24px;
				line-height: 24px;
			}
			p {
				margin-bottom: 24px;
			}
		}
	}
}