.in-stock-flooring {
	.in-stock-flooring-header-area {
		width: 100%;
		position: relative;
		float: left;
		background: $white;
		border-bottom: 1px solid $gray;
		.inner {
			width: 1072px;
			padding: 66px 0;
		    margin: 0 auto;
			h2 {
				  font-weight: 700;
				  font-size: 20px;
				  text-transform: captialize;
				  color: #60646D;
				  border-bottom: 1px solid $gold;
				  display: inline-block;
				  padding-bottom: 20px;
			}
			div {
				color: #424850;
				font-size: 18px;
				@include secondary-font;
				padding-top: 18px;
				-webkit-font-smoothing: subpixel-antialiased;	
			}
		}
	}
	h3 {
		font-weight: 700;
		font-size: 20px;
		text-transform: captialize;
		color: #60646D;
		padding: 72px 0 40px;
	}
	.in-stock-flooring-tables {
		padding: 0 72px 98px 72px;
	}
	table {
		font-size: 14px;
		width: 100%;
		thead {
			tr {
				border-bottom: 2px solid $gold;
			}
			th {
				color: $gold;
				text-transform: capitalize;
				text-align: left;
				font-wieght: 400;
				padding: 0 12px 18px 12px;
			}
		}
		tbody {
			td {
				text-transform: capitalize;
				text-align: left;
				color: #60646D;
				line-height: 20px;
				border-bottom: 1px solid $gray;
				padding: 28px 12px;
				a {
					text-decoration: underline;
				}
				&.nowrap {
					white-space: nowrap;
				}
			}
		}
	}
}
@include queryWidth(max, 1200px) {
	.in-stock-flooring {
		.in-stock-flooring-header-area {
			.inner {
				width: 100%;
				padding: 66px 40px;
			}
		}
	}
}
@include tablet {
	.in-stock-flooring {
		.in-stock-flooring-tables {
			border-right: 2px solid $gray;
			overflow-x: scroll;
			h3 {
				span {
					font-size: 10px;
				}
			}
			table {
				min-width: 1200px;
			}
		}
	}
}