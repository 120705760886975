@include mobile {
	.display-for-small-only {
	  display:block;
	}

	.hide-for-small, .display-for-medium-up, .display-for-medium-only {
	  display: none ;
	}

	.small-1 {
	  width: 8.33333%; 
	}

	.small-2 {
	  width: 16.66667%; 
	}

	.small-3 {
	  width: 25%; 
	}

	.small-4 {
	  width: 33.33333%; 
	}

	.small-5 {
	  width: 41.66667%; 
	}

	.small-6 {
	  width: 50%; 
	}

	.small-7 {
	  width: 58.33333%; 
	}

	.small-8 {
	  width: 66.66667%; 
	}

	.small-9 {
	  width: 75%; 
	}

	.small-10 {
	  width: 83.33333%; 
	}

	.small-11 {
	  width: 91.66667%; 
	}

	.small-12 {
	  width: 100%; 
	}
}