$tabletWidth: 1100px;
$phabletWidth: 730px;
$mobileWidth: 550px;

@mixin tablet {
  @media only screen and (max-width: $tabletWidth) {
    @content;
  }
}

@mixin phablet {
  @media only screen and (max-width: $phabletWidth) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobileWidth) {
    @content;
  }
}
.contact-form{
  width:100%;
  max-width:790px;
  margin:0 auto;
  display:block;
  padding-top:80px;
  padding-bottom:80px;
  @media(max-width: 850px){
    padding: 50px 20px 80px 20px;

  }
  .form-row{
    display:block;
  }
  .form-column{
    float:left;
    width:calc(50% - 30px);
    display:inline-block;
    &:nth-child(odd){
      margin-right:30px;
    }
    &:nth-child(even){
      margin-left:30px;
    }
    @media(max-width: 850px){
      width:100%;
      &:nth-child(even), &:nth-child(odd){
        margin-left:0; margin-right:0;
      }
    }
  }
  h3{
    padding-bottom:14px;
    display:inline-block;
    text-transform:uppercase;
    font-size:16px;
    margin-bottom:30px;
    @include phablet{
      display:block;
    }
  }
  .form-error, .form-success {
    font-size: 14px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  .form-error {
    color: #DB4437;
  }
  label{
    color:#000;
    font-size:14px;
    font-weight:300;
    display:block;
    margin-bottom:12px;
  }
  input, textarea{
    background:#fff;
    border:none;
    outline:none;
    height:50px;
    width:calc(100% - 30px);
    padding: 0 15px;
    margin-bottom:40px;
  }
  textarea{
    padding-top:20px;
    height:150px;
  }
  select{
    position:relative;
    @include border-radius(0);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0px;
    background: #fff;
    color:#000;
    font-size:14px;
    height: 50px;
    line-height: 50px;
    width:100%;
    padding: 0 15px;
    text-align:center;
    margin-bottom:40px;
  }
  .select-fix {
    position:relative;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #000;
      position: absolute;
      right: 20px;
      top: 23px;
    }
  }

}

.section-container {
  max-width: 1350px;
  display: block;
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
  &.short {
    max-width: 1160px;
    padding: 0 25px;
  }
  &.long {
    max-width:100%;
    padding:0 25px;
    @include tablet{
      padding:0;
    }
  }
  @include tablet {
    width: 100%;
  }
  @include phablet {
    padding: 0;
  }
}

.courses-header{
  height:450px;
  padding:140px 0 35px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:left 50% top 75px;
  position: relative;
  &::after{
    position: absolute;
    content:"";
    display: block;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,.6);
    top:0;
    left:0;
  }
  .section-container{
    padding-left:90px;
    padding-right:90px;
    position: relative;
    z-index: 2;
    @include phablet{
      padding-left:30px;
      padding-right:30px;
    }
  }
  h1{
    font-size:44px;
  }
  h1,p{
    color:#FFF;
    font-family: montserrat, sans-serif;
    margin-bottom:25px;
  }
  @include mobile{
    height:400px;
    h1{
      font-size:24px;
    }
  }
}
.courses-container,.contact-container{
  max-width:1398px;
  padding:0 90px;
  @include phablet{
    padding:0;
  }
}
.registration-form{
  border-left:1px solid #b3afa7;
  border-right:1px solid #b3afa7;
}
.course-card{
  max-width:1398px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-left:1px solid #b3afa7;
  border-right:1px solid #b3afa7;
  padding:80px 30px 80px 90px;
  border-bottom:1px solid #b3afa7;
  .description{
    width:100%;
    margin-right:40px;
    padding:0 10px;
    h2,h3{
      font-family: montserrat, sans-serif;
      text-transform: uppercase;
      color:#61656e;
      letter-spacing: unset;
      hyphens: auto
    }
    h2,hr,p,ul{
      margin-bottom:30px;
    }
    p,ul{
      color:#424850;
      hyphens: auto
    }
    h2{
      font-size:31px;
      @include phablet{
        font-size:20px;
      }
    }
    h3{
      margin-bottom:22px;
      font-size:17px;
      @include phablet{
        font-size:16px;
      }
    }
    p{
      font-family: baskerville-urw, serif;
      font-weight: 400;
      font-style: normal;
      font-size:18px;
      line-height:28px;
      @include phablet{
        font-size:15px;
      }
    }
    ul{
      font-family: montserrat, sans-serif;
      font-size:14px;
      padding-left:20px;
      list-style-type: none;
      li{
        display: flex;
        align-items: center;
        margin-bottom:-10px;
        @include phablet{
          margin-bottom:0;
        }
        &::before{
          color: #8d8146;
          font-size:38px;
          margin-left:-16px;
          position:relative;
          top:-3px;
          margin-right:10px;
          content: '\2022';
        }
      }

    }
  }
  .feature-image{
    width:100%;
    height:100%;
    img{
      width:100%;
      height:100%;
      object-fit: cover;
    }
  }
  &:nth-child(even){
    flex-direction: row-reverse;
    .description{
      margin-right:0;
    }
    .feature-image{
      margin-right:40px;
    }
  }
  @include tablet {
    flex-direction: column-reverse !important;
    padding:80px 25px;
    .description{
      margin-right:0 !important;
    }
    .feature-image{
      margin-right:0 !important;
      margin-bottom:80px;
      height:395px;
    }
  }
  @include phablet{
    padding:35px 15px;
    .feature-image{
      margin-bottom:35px;
    }
  }
}
.registration-form{
  h2{
    padding:75px 42px 25px;
    font-size:20px;
    font-family: montserrat, sans-serif;
    border-bottom:1px solid #b3afa7;
    letter-spacing: unset;
    text-transform: uppercase;
    color:#61656e;
  }
  form{
    padding:27px 25px 65px;
    position: relative;
    input,textarea,select{
      border:1px solid #b3afa7;
      font-family: baskerville-urw, serif;
      font-weight: 400;
      font-style: normal;
      color:#424850;
      width:100%;

    }
    select{
      background-image:url('../images/arrow-down-orange.svg');
      background-repeat:no-repeat;
      background-position: right 20px bottom 50%;
      background-size:12px 9px;
    }
    button{
      margin:0 auto;
    }
  }
}
#registration-response{
  text-align:center;
  position: absolute;
  width:100%;
  left:0;
  bottom:0;
  color:#424850;
  font-family: montserrat, sans-serif;
  text-transform: uppercase;

}


