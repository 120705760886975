/* column layout */

.display-for-small-only, .display-for-medium-down, .display-for-medium-only {
  display: none;
}
.display-for-large-only, .display-for-medium-up {
  display: block;
}
.row {
	width: 100%;
}
.row,
.columns, .column {
	float: left;
	position: relative;
}
.large-1 {
  width: 8.33333%; 
}

.large-2 {
  width: 16.66667%; 
}

.large-3 {
  width: 25%; 
}

.large-4 {
  width: 33.33333%; 
}

.large-5 {
  width: 41.66667%; 
}

.large-6 {
  width: 50%; 
}

.large-7 {
  width: 58.33333%; 
}

.large-8 {
  width: 66.66667%; 
}

.large-9 {
  width: 75%; 
}

.large-10 {
  width: 83.33333%; 
}

.large-11 {
  width: 91.66667%; 
}

.large-12 {
  width: 100%; 
}

.large-fifth {
  width: 20%
}

.large-60 {
  width: 60%;
}

.large-40 {
  width: 40%;
}

.full {
  width: 100%;
}