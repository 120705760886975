#blog-landing-posts.textured-holder {
	.inner {
		h2 {
			top: 0;
		}
	}
}
.blog-landing-post {
	height: 443px;
	padding-bottom: 4px;
	border-bottom: 1px solid $gray;
	.image-column {
		padding: 18px 24px;
		@include transition(padding 0.3s ease);
		img {
			width: 100%;
			height: auto;
			opacity: 0.85;
			@include transition(margin 0.3s ease, opacity 0.3s ease,width 0.3s ease);
			display: block;
		}
	}
	.info-column {
		padding: 26px 44px 0 44px;
		.date {
			font-size: 16px;
			color: $gold;
		}
		h3 {
			font-size: 20px;
			margin: 12px 0 30px;
			color: #61656E;
			text-transform: capitalize;
		}
		.summary {
			max-height: 273px;
			overflow: hidden;
			@include secondary-font;
			font-size: 16px;
			color: #424850;
			border-top: 1px solid $gold;
			border-bottom: 1px solid $gold;
			padding: 20px 0;
			line-height: 29px;
		}
		.read-more {
			padding-top: 22px;
			font-size: 15px;
			color: #61656E;
			text-transform: uppercase;
			font-weight: 700;
			a {
				&:after {
					content: ' ';
	                background: url(../images/sprite.png) no-repeat -215px -4px;
	                width: 54px;
	                height: 20px;
	                display: inline-block;
	                margin-left: 16px;
	                position: relative;
	                top: 7px;
				}
			}
		}
	}
	&:hover {
		border-bottom: 5px solid $gold;
		padding-bottom:  0;
		.image-column {
			padding: 0;
			img {
				  opacity: 1;
				  margin: 0;
			}
		}
	}
}
.load-more-blog-posts, .load-more-search-results {
	height: 112px;
	text-align: center;
	a {
		position: relative;
		margin-top: 44px;
		font-size: 15px;
		color: #95999C;
		text-decoration: underline;
		display: inline-block;
		text-transform: uppercase;
		img {
			width: 20px;
			height: 20px;
			position: absolute;
			right: -30px;
			top: 0;
			display: none;
		}
	}
	.columns:first-child {
		border-right: 1px solid $gray;
		height: 100%;
	}
}

#blog-post-content {
	.inner {
		.content {
			padding: 62px 180px;
			color: #424850;
			@include secondary-font;
			font-size: 20px;
			h2, h3, h4, h5 {
				color: #61656E;
				@include primary-font;
			}
			h3 {
				font-size: 22px;
				margin: 40px 0;
			}
			h4 {
				font-size: 20px;
				font-wieght: 400;
			}
			p {
				margin: 20px 0;
			}
			img {
				display: inherit;
				margin: 40px auto;
				max-width: 100%;
				height: auto;
			}
		}
		.load-more-blog-posts {
			border-top: 1px solid $gray;
		}
		
	}
}
@include queryWidth(max, 1400px) { 
	.blog-landing-post {
		.image-column {
			position: relative;
			min-height: 443px;
			@include transition(all 0s);
			img { 
				opacity: 1;
				max-height: 100%;
			    width: calc(100% - 24px); 
			    height: auto;
			    position: absolute;  
			    top: 0;  
			    bottom: 0;  
			    left: 24px;  
			    right: 0;  
			    margin: auto;
			    @include transition(margin 0s, opacity 0.3s ease,width 0s);
			}
		}
		&:hover {
			.image-column {
				padding: 18px 24px;
				img {
					  opacity: 1;
					  margin: auto;
				}
			}
		}
	}

}
@include queryWidth(max, 1150px) { 
	.blog-landing-post.blog {
		.info-column {
			.summary {
				font-size: 14px;
				-webkit-font-smoothing: subpixel-antialiased;	
			}
		}
	}
}
@include queryWidth(max, 1024px) {
	.blog-landing-post, .blog-landing-post.blog {
		height: auto;
		.image-column {
			min-height: 0;
			img {
				position: relative;
				left: 0;
				margin: 0;
				width: 100%;
			}
		}
		&:hover {
			.image-column {
				padding: 18px 24px;
				img {
					  opacity: 1;
					  margin: 0;
				}
			}
		}
		.info-column {
			.summary {
				font-size: 13px;
				padding: 12px 0;
				line-height: 22px;
				-webkit-font-smoothing: subpixel-antialiased;	
			}
		}
	}
	.load-more-blog-posts {
		height: 62px;
		a {
			margin-top: 22px;
		}
	}
	#blog-post-content {
		.inner {
			.content {
				padding: 40px 50px;
				h3:first-child {
					margin-top: 20px;
				}
			}
		}
	}
}
@include queryWidth(max, 890px) {
	.blog-landing-post, .blog-landing-post.blog {
		.info-column {
			.date {
				font-size: 12px;
			}
			h3 {
				font-size: 14px;
				margin-bottom: 18px;
			}
			.read-more {
				padding-top: 16px;
				font-size: 13px;
			}
		}
	}
}
@include mobile {
	.blog-landing-post, .blog-landing-post.blog {
		padding: 16px;
		.inner-post {
			max-width: 400px;
			float: none;
			margin: 0 auto;
		}
		.image-column {
			padding: 0 0 12px 0;
			text-align: center;
			img {
				max-width: 100%;
				width: auto;
				left: auto;
				right: 0;
				display: inline;
			}
			
		}
		.info-column {
			padding: 0;
			.read-more {
				padding-bottom: 12px;
			}
		}
		&:hover {
			padding: 16px 16px 12px 16px;
			.image-column {
				padding: 0 0 12px 0;
				img {
					  opacity: 1;
					  margin: auto;
				}
			}
		}
	}
	#blog-post-content {
		.inner {
			.content {
				padding: 40px 20px;
				font-size: 16px;
				h3:first-child {
					margin-top: 10px;
				}
				h3 {
					font-size: 18px;
					margin: 24px 0;
				}
				h4 {
					font-size: 16px;
				}
			}
		}
	}
}