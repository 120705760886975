.newsletter-row-holder {
	background: $grayBackground;
	width: 100%;
	float: left;
	color: $white;
	.newsletter-row {
		padding: 58px 132px;
		h6 {
			font-size: 15px;
			text-transform: capitalize;
			font-weight: 400;
			margin-bottom: 34px;
		}
		.text-input-holder {
			width: 75%;
			position: relative;
			height: 52px;
			display: block;
			float: left;
			margin-right: 5%;
			&.focus {
				&:before {
					content: ' ';
					position: absolute;
					display: block;
					height: 14px;
					top: 19px;
					left: 22px;
					width: 2px;
					background: $black;
					z-index: 1;
				}
				&:after {
					content: ' ';
					position: absolute;
					display: block;
					height: 5px;
					bottom: 0;
					left: 0;
					width: 100%;
					background: $gray;
				}
			}
		}
		input[type="text"] {
			background: transparent;
			border: 1px solid $tan;
			@include secondary-font;
			color: $white;
			font-size: 15px;
			line-height: 50px;
			width: 100%;
			padding: 0 18px;
			display: inline-block;
			float:left;
			position: relative;
			&::-webkit-input-placeholder {
			   	color: $white;
			}

			&:-moz-placeholder { /* Firefox 18- */
			   color: $white; 
			}

			&::-moz-placeholder {  /* Firefox 19+ */
			   color: $white; 
			}

			&:-ms-input-placeholder {  
			   color: $white; 
			}
			&:focus {
				outline:none;
				box-shadow: none;
				background-color: $white;
				padding: 0 30px;
				color: $black;
				&:after {
					content: ' ';
					height: 4px;
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					background: $gray;

				}
				&::-webkit-input-placeholder {
					color: $black;
					font-weight: 700;
				}

				&:-moz-placeholder { /* Firefox 18- */
					color: $black;
				   	font-weight: 700;
				}

				&::-moz-placeholder {  /* Firefox 19+ */
					color: $black;
					font-weight: 700;
				}

				&:-ms-input-placeholder {  
					color: $black;
					font-weight: 700;
				}
			}
			&.invalid {
				&::-webkit-input-placeholder {
					color: #cc0000;
					font-weight: 700;
				}

				&:-moz-placeholder { /* Firefox 18- */
					color: #cc0000;
				   	font-weight: 700;
				}

				&::-moz-placeholder {  /* Firefox 19+ */
					color: #cc0000;
					font-weight: 700;
				}

				&:-ms-input-placeholder {  
					color: #cc0000;
					font-weight: 700;
				}
			}
			&.submitted {
				&::-webkit-input-placeholder {
					font-weight: 700;
				}

				&:-moz-placeholder { /* Firefox 18- */
				   	font-weight: 700;
				}

				&::-moz-placeholder {  /* Firefox 19+ */
					font-weight: 700;
				}

				&:-ms-input-placeholder {  
					font-weight: 700;
				}
			}
		}
		input[type="submit"] {
			border: 0;
			background: $gold;
			height: 50px;
			border-radius: 25px;
			text-transform: uppercase;
			width: 20%;
			text-align: center;
			font-size: 15px;
			display: inline-block;
			float:left;
			@include transition(color 0.15s ease-out, background-color 0.15s ease-out);
			&:hover {
				color: $gold;
				background-color: $white;
			}
			&.disabled {
				background: $gray;
				color: $background-dark-gray;
				cursor: default
			}
		}
	}
}
#footer {
	background: $tan;
	width: 100%;
	float: left;
	color: $black;
	.inner-footer-top {
		height: 120px;
		border-bottom: 1px solid $gray;
		.wrapper {
			height: 100%;
		}
	}
	.inner-footer-bottom {
		height: 472px;
		.wrapper {
			height: 100%;
		}
		.columns.center-left {
			.columns {
				height: 100%;
				padding: 62px 44px;
				&:first-child {
					border-right: 1px solid $gray;
				}
				a {
					display: inline-block;
					font-size: 13px;
					font-weight: 700;
					clear: both;
					text-transform: uppercase;
					margin-bottom: 16px;
				}
				.sub-links {
					margin-bottom: 16px;
					a {
						@include secondary-font;
						font-weight: 400;
						text-transform: none;
						margin-bottom: 0;
						display: block;
					}
				}
			}
		}
		.columns.center-right {
			padding: 29px 44px;
			h6 {
				font-size: 13px;
				font-weight: 700;
				text-transform: uppercase;
				margin: 34px 0 25px 0;
			}
			.parter-award {
				margin-bottom: 25px;
			}
		}
		.columns.right {
			a.logo {
				margin: 186px 0 0 28px;
				height: 168px;
				width: 36px;
				display: block;
				background-position: 0 -99px;
			}
		}
	}
	.columns.left, .columns.right {
		width: 92px;
		height: 100%;
	}
	.columns.right {
		.inner-footer-top & {
			border-bottom: 1px solid $gray;
		}
	}
	.columns.left {
		border-right: 1px solid $gray;
		.inner-footer-top & {
			border-bottom: 1px solid $gray;
		}
	}
	.columns.center-left {
		width: 488px;
		height: 100%;
		border-right: 1px solid $gray;
		.inner-footer-top & {
			border-bottom: 1px solid $gray;
		}
		.inner-footer-bottom & {
			.columns {
				height: 100;
				&:first-child {
					border-right: 1px solid $gray;
				}
			}
		}
	}
	.columns.center-right {
		height: 100%;
		width: 728px;
		border-right: 1px solid $gray;
	}
	.copyright {
		padding: 75px 20px 0 46px;
		text-transform: uppercase;
		font-size: 13px;
	}
	.partner-awards {
		img {
			width: 100px;
			vertical-align: middle;
			display: inline-block;
			margin-right: 10px;
			&:first-child {
				width: 140px;
			}
		}
	}
	.footer-social-links {
		padding: 70px 0 0 44px;
		a {
			width: 25px;
			height: 25px;
			display: inline-block;
			margin-right: 8px;
			&.facebook {
				background-position: -50px -1px;
			}
			&.houzz {
				background-position: -75px -1px;
			}
			&.pinterest {
				background-position: -99px -1px;
			}
			&.instagram {
				background-position: -124px -1px;
			}
		}
	}
	a.up-arrow {
		width: 32px;
		height: 50px;
		display: inline-block;
		background-position: -38px -26px;
  		margin: 35px;
	}
}
#footer-bottom {
	background: $grayBackground;
	width: 100%;
	float: left;
	color: $tan;
	line-height: 52px;
	font-size: 13px;
	@include secondary-font;
	-webkit-font-smoothing: subpixel-antialiased;
	.wrapper {
		padding-left: 134px;
		span.sprite {
			width: 15px;
  			height: 19px;
 			display: inline-block;
  			background-position: -147px -4px;
  			position: relative;
  			top: 6px;
  			margin-right: 16px;
		}
		div {
			display: inline-block;
		}
	}
}

@include queryWidth(max, 1400px) { 
	#footer {
		.columns.left, .columns.right {
			width: 7%;
		}
		.columns.center-left {
			width: 35%;
		}
		.columns.center-right {
			width: 51%;
		}
		a.up-arrow {
			margin-left: calc(50% - 16px);
			margin-right: calc(50% - 16px);
		}
		.inner-footer-bottom {
			.columns.right {
				a.logo {
					margin-left: calc(50% - 16px); 
				}
			}
			.columns.center-right {
				padding: 29px;
			}
		}
	}
	#footer-bottom {
		height: auto;
		padding-bottom: 20px;
		min-height: 52px;
		.wrapper {
			span.sprite {
				top: -4px;
			}
			padding: 18px 24px 0 134px;
			line-height: normal;
			.aligned {
				width: calc(100% - 60px);
			}
		}
	}
}

@include tablet {
	#footer {
		.inner-footer-top {
			height: 90px;
			border-color: #a3a3a3;
			.columns {
				border-color: #a3a3a3;
			}
			.columns.left {
				border-bottom: 0;
			}
			.columns.center-right {
				width: 86%;
				border-color: #a3a3a3;
				.logo {
					width: 50%;
					float: left;
					padding: 23px 0 0 40px;
					a {

					}
				}
				.footer-social-links {
					padding: 32px 38px 0 0;
					width: 50%;
					float: left;
					text-align: right;
				}
			}
		}
		.columns.full {
			border-left: 1px solid #a3a3a3;
			border-right: 1px solid #a3a3a3;
			border-bottom: 1px solid #a3a3a3;
			margin-left: 7%;
			margin-right: 7%;
			width: 86%;
			.copyright {
				  padding: 0 0 0 42px;
				  line-height: 82px;
			}
		}
		a.up-arrow {
			margin-top: 20px;
			margin-bottom: 20px;
		}
		.inner-footer-bottom {
			height: auto;
			.columns {}
			.columns.left {
				min-height: 100px;
				border-color: #a3a3a3;
				border-right: 0;
			}
			.columns.center-left {
				width: 86%; 
				margin-right: 7%;
				border-color: #a3a3a3;
				border-bottom: 1px solid #a3a3a3;
				border-left: 1px solid #a3a3a3;
				display: flex;
				height: auto;
				.columns:first-child {
					border-color: #a3a3a3;
				}
				.columns {
					padding: 44px;
					flex: 1;
					height: auto;
				}
			}
			.columns.center-right {
				margin-left: 7%;
				margin-right: 7%;
				width: 86%;
				border-left: 1px solid #a3a3a3;
				border-right: 1px solid #a3a3a3;
				padding: 0;
				h6 {
					margin-top: 17px;
				}
				.partners {
					border-bottom: 1px solid #a3a3a3;
					padding: 29px;
				}
				.awards {
					padding: 29px;
				}
			}
		}
	}
	#footer-bottom { 
		.wrapper {
			span.sprite {
				top: -4px;
			}
			padding-left: calc(7% + 29px);
			.aligned {
				width: calc(100% - 60px);
			}
		}
	}
}

@include mobile {

	.newsletter-row-holder {
		.newsletter-row {
			padding: 40px 20px 50px 20px;
			.text-input-holder {
				width: 100%;
			}
			input[type="submit"], input[type="text"] { 
				width: 100%;
				display: block;
			}	
			input[type="submit"] {
				margin-top: 36px;
			}
			h6 {
				margin-top: 16px;
			}
		}
	}
	#footer {
		.inner-footer-top { 
			height: 148px;
			border: 0;
			.columns.center-right {
				width: 100%;
				border: 0;
				.logo, .footer-social-links {
					width: 100%;
					padding: 23px 0 18px 40px;
					border-bottom: 1px solid #a3a3a3;
				}
				.logo {
					img {
						height: 30px;
					}
				}
				.footer-social-links {
					text-align: left;
				}
			}
		}
		.columns.full {
			border-left: 0;
			border-right: 0;
			margin: 0;
			padding: 23px 40px 23px 40px;
			width: 100%;
			.copyright {
				line-height: normal;
				font-weight: bold;
				padding: 0;
			}
		}
		.inner-footer-bottom {
			width: 100%;
			.columns.center-left {
				width: 100%;
				border-left: 0;
				border-right: 0;
				display: block;
				.columns {
					padding: 0 40px 23px 40px;
					&:first-child {
						padding: 23px 40px 0px 40px;
					}
				}
			}
			.columns.center-right {
				width: 100%;
				border-left: 0;
				border-right: 0;
				margin: 0;
				.partners {
					padding: 23px 40px 23px 40px;
					img {
						margin-bottom: 20px;
					}
					img:first-child {
						width: 210px;
						margin-bottom: 20px;
					}
					h6 {
						margin-top: 0;
					}
				}
				.awards {
					padding: 23px 40px 23px 40px;
					h6 {
						margin-top: 0;
					}
				}
			}
		}
	}
	#footer-bottom {
		height: auto;
		padding-bottom: 20px;
		min-height: 100px;
		.wrapper {
			padding: 28px 24px 0 24px;
			line-height: normal;
			span.sprite {
				background-position: -1px -268px;
				height: 42px;
				width: 42px;
				display: block;
				float: left;
				vertical-align: middle;
				marging-top: 4px;
				top: 6px;
			}
			div.aligned {
				width: calc(100% - 58px);
				display: table;
				float: left;
				margin-right: 0;
				height: 54px;
				div {
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
	}
	#mobile-scroll {
	display: inline-block;
	height: 46px;
	width: 28px;
	background: $black url(../images/sprite.png) no-repeat -39px -28px;
	position: fixed;
	top: 50%;
	margin-top: -24px;
	right: 0;
	cursor: pointer;
	display: none;
	@include box-shadow(0 0 6px rgba(0, 0, 0, 0.125),0 6px 12px rgba(0, 0, 0, 0.25));
	&.scrolled {
		display: block;
	}
}
}

