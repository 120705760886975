@include tablet {
/* column layout */
  .display-for-large-only {
    display: none;
  }
  .display-for-medium-down, .display-for-medium-only  {
    display: block;
  }

  .medium-1 {
    width: 8.33333%; 
  }

  .medium-2 {
    width: 16.66667%; 
  }

  .medium-3 {
    width: 25%; 
  }

  .medium-4 {
    width: 33.33333%; 
  }

  .medium-5 {
    width: 41.66667%; 
  }

  .medium-6 {
    width: 50%; 
  }

  .medium-7 {
    width: 58.33333%; 
  }

  .medium-8 {
    width: 66.66667%; 
  }

  .medium-9 {
    width: 75%; 
  }

  .medium-10 {
    width: 83.33333%; 
  }

  .medium-11 {
    width: 91.66667%; 
  }

  .medium-12 {
    width: 100%; 
  }
}
