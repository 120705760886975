.contact-page-holder, .showroom-contact-form-row {
	.inner {
		border-bottom: 1px solid $gray;
		h2 {
			border-top: 0;
		}
		form {
			padding: 14px 12px;
			.columns.large-6, .columns.large-4, .columns.large-12 {
				padding: 0 12px;
				margin: 14px 0;
			}
			h3 {
				position: relative;
				float: left;
				width: 100%;
				padding: 10px 16px 0 28px;
				color: $grayText;
				font-size: 16px;
			}
			.columns.large-12.submit-button-holder {
				text-align: center;
				margin-top: 48px;
				margin-bottom: 48px;
			}
			.success-message, .error-message {
				padding: 12px;
				color: #60646D;
			}
			.error-message {
				color: $error;
			}
			input[type="text"], textarea, select {
				width: 100%;
				display: block;
				float: left;
				height: 50px;
				line-height: 48px;
				padding: 0 22px;
				font-size: 15px;
				border: 1px solid $gray;
				@include secondary-font;
				-webkit-font-smoothing: subpixel-antialiased;
				border-radius: 0;
				background: $white;
			}
			input[type="text"], textarea {
				&::-webkit-input-placeholder {
					color: $grayBackground;
				}

				&:-moz-placeholder { /* Firefox 18- */
				   	color: $grayBackground; 
				}

				&::-moz-placeholder {  /* Firefox 19+ */
				   	color: $grayBackground; 
				}

				&:-ms-input-placeholder {  
				   	color: $grayBackground; 
				}
				&:focus {
					outline:none;
					box-shadow: none;
					background-color: $grayBackground;
					color: $white;
					border: 1px solid $grayBackground;
					padding: 0 30px;
					&::-webkit-input-placeholder {
						color: $white;
					}

					&:-moz-placeholder { /* Firefox 18- */
					   	color: $white; 
					}

					&::-moz-placeholder {  /* Firefox 19+ */
					   	color: $white; 
					}

					&:-ms-input-placeholder {  
					   	color: $white; 
					}
				}
			}
			.text-input-holder {
				width: 100%;
				position: relative;
				float: left;
				&.focus {
					&:before {
						content: ' ';
						position: absolute;
						display: block;
						height: 14px;
						top: 19px;
						left: 22px;
						width: 2px;
						background: $gold;
						z-index: 1;
					}
					&:after {
						content: ' ';
						position: absolute;
						display: block;
						height: 5px;
						bottom: 0;
						left: 0;
						width: 100%;
						padding: 0 24px;
						background: $gold;
					}
				}
			}
			
			textarea {
				height: 200px;
			}
			.select-style {
			    border: 1px solid $gray;
			    width: 100%;
			    overflow: hidden;
			    position: relative;
			    &:after {
			    	content: '';
			    	display: block;
			    	width: 12px;
			    	height: 9px;
			    	position: absolute;
			    	top: 20px;
			    	right: 16px;
			    	background: url("../images/icon-select.png") no-repeat;
			    }
			    select {
				    padding: 0 28px 0 22px;
				    border: none;
				    box-shadow: none;
				    background: transparent;
				    background-image: none;
				    -webkit-appearance: none;
				    &:focus {
				    	outline: 0;
				    }
				}
			}

			&.contact-success {
				*:not(.success-message) {
					display: none;
				}
			}

		}
	}
}
@include tablet {
	.contact-page-holder {
		.inner {
			form {
				.columns.large-12.submit-button-holder {
					margin: 12px 0;
				}
			}
		}
	}
}
@include mobile {
	.contact-page-holder {
		.inner {
			form {
				.columns.large-12.submit-button-holder {
					margin: 0;
				}
			}
		}
	}
}