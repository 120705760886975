.textured-holder.search-results {
	.inner {
		position: relative;
		h2 {
			span {
				color: $gold;
				font-size: 14px;
				font-weight: 400;
				padding-left: 16px;
			}
		}
		.search-results-form {
			position: absolute;
			right: 16px;
			top: 42px;
			width: 400px;
			.text-input-holder {
				width: 100%;
				position: relative;
				float: left;
				&.focus {
					&:before {
						content: ' ';
						position: absolute;
						display: block;
						height: 14px;
						top: 19px;
						left: 22px;
						width: 2px;
						background: $gold;
						z-index: 1;
					}
					&:after {
						content: ' ';
						position: absolute;
						display: block;
						height: 5px;
						bottom: 0;
						left: 0;
						width: 100%;
						padding: 0 24px;
						background: $gold;
					}
					button {
						top: 12px;
					}
				}
			}
			input[type="text"] {
				width: 100%;
				display: block;
				float: left;
				height: 50px;
				line-height: 48px;
				padding: 0 22px;
				font-size: 15px;
				border: 1px solid $gray;
				@include secondary-font;
				-webkit-font-smoothing: subpixel-antialiased;
				border-radius: 0;
				background: $white;
			}
			input[type="text"] {
				&::-webkit-input-placeholder {
					color: $grayBackground;
				}

				&:-moz-placeholder { /* Firefox 18- */
				   	color: $grayBackground; 
				}

				&::-moz-placeholder {  /* Firefox 19+ */
				   	color: $grayBackground; 
				}

				&:-ms-input-placeholder {  
				   	color: $grayBackground; 
				}
				&:focus {
					outline:none;
					box-shadow: none;
					background-color: $grayBackground;
					color: $white;
					border: 1px solid $grayBackground;
					padding: 0 30px;
					&::-webkit-input-placeholder {
						color: $white;
					}

					&:-moz-placeholder { /* Firefox 18- */
					   	color: $white; 
					}

					&::-moz-placeholder {  /* Firefox 19+ */
					   	color: $white; 
					}

					&:-ms-input-placeholder {  
					   	color: $white; 
					}
				}
			}
			button {
				border: none;
				width: 55px;
				height: 20px;
				background-position: -214px 0;
				position: absolute;
				top: 15px;
				right: 5px;
			}
		}
		.results-holder {
			.result {
				padding: 42px;
				border-bottom: 1px solid $gray;
				&.image {
					min-height: 226px;
				}
				h3 {
					font-weight: 700;
				    font-size: 20px;
				    text-transform: uppercase;
				    color: #60646D;
				    line-height: 24px;
				    margin-bottom: 12px;
				    a {
				    	display: inline-block;
				    }
					span {
						color: $gold;
						display: inline-block;
						font-size: 16px;
						font-weight: 400;
						&:before {
							content: '';
							display: inline-block;
							height: 24px;
							width: 1px;
							margin: 0 14px;
							background-color: $gold;
							vertical-align: middle;
							position: relative;
							top: -2px;
						}
					}
				}
				.summary {
					color: $black;
					font-size: 16px;
					line-height: 28px;
					@include secondary-font;
					-webkit-font-smoothing: subpixel-antialiased;
				}
				.image-holder {
					position: relative;
					float: left;
					margin-right: 40px;
					height: 142px;
					overflow: hidden;
					img {
						width: auto;
						height: 100%;
					}
				}
			}
		}
		.no-results {
			position: relative;
			text-align: center;
			width: 100%;
			padding: 84px 0;
			font-size: 16px;
			color: #61656E;
			text-transform: uppercase;
			a {
				text-decoration: underline;
			}
		}
	}
}
@include tablet {
	.textured-holder.search-results {
		.inner {
			.search-results-form {
				display: none;
			}
			.results-holder {
				.result {
					padding: 28px;
					h3 {
						span {
							display: block;
							&:before {
								display: none;
							}
						}
					}
					.image-holder {
						img {
							padding-bottom: 20px;
						}
					}
				}
			}
		}
	}
}
@include mobile {
	.textured-holder.search-results {
		.inner {
			.results-holder {
				.result {
					.image-holder {
						display: none;
					}
				}
			}
		}
	}
}