.home-showrooms-holder {
	float: left;
	width: 100%;
	background: url(../images/texture-bg.jpg) repeat;
	.home-showrooms {
		background: $white;
		width: 1216px;
		margin: 0 auto;
		border-bottom: 1px solid $gray;
		border-left: 1px solid $gray;
		border-right: 1px solid $gray;
		h2 {
			font-weight: 700;
			font-size: 20px;
			text-transform: capitalize;
			color: $grayText;
			border-bottom: 1px solid $gray;
			padding: 68px 0 22px 38px;
		}
		.home-showroom {
			padding: 48px 26px;
			border-left: 1px solid $gray;
			float: left;
			width: 16.666%;
			height: 390px;
			&:first-child {
				border-left: 0;
			}
			h3 {
				font-size: 17px;
				color: $gold;
				text-transform: uppercase;
				margin-bottom: 14px;
				min-height:60px;
				&:before {
    				content: ' ';
    				background: url(../images/sprite.png) no-repeat -1px -1px;
  					width: 11px;
  					height: 16px;
	    			display: inline-block;
	    			margin-right: 4px;
	    			position: relative;
	    			top: 2px;
    			}
			}
			.address, .phone, .email, .hours {
				font-size: 13px;
				color: $grayText;
				@include secondary-font;
				line-height: 16px;
				margin-bottom: 15px;
				-webkit-font-smoothing: subpixel-antialiased;
				h4 {
					@include primary-font;
					text-transform: uppercase;
					margin: 0 0 2px 0;
				}
				a {
					color: $gold;
				}
			}
			.address {
				min-height:48px;
				span {
					display: block;
				}
			}
			.hours {
				min-height:77px;
				strong {
					font-weight: 400;
				}
			}
		}
	}
}
.nationwide-row {
    width: 100%;
    float: left;
    color: $white;
    position: relative;
    .background {
    	position: absolute;
    	width: 100%;
    	height: 100%;
    	top: 0;
    	left: 0;
    	bottom: 0;
    	right: 0;
    	.columns:last-child {
    		background-color: $gold;
    		height: 100%;
    	}
    }
    .columns {
    	padding: 60px;
    	line-height: 26px;
    	font-size: 18px;
    	@include secondary-font;
    	h2 {
    		@include primary-font;
	    	font-size: 28px;
	    	text-transform: uppercase;
	    	font-weight: 700;
	    	line-height: 48px;
	   		border-bottom: 1px solid $white;
	   		padding-bottom: 26px;
	    }
	    div {
	    	padding: 30px 0;
	    	min-height: 146px;
	   		border-bottom: 1px solid $white;
	   		line-height: 26px;
	    }
    }
    .button {
    	@include primary-font;
    	height: 52px;
    	display: inline-block;
    	text-align: center;
    	line-height: 52px;
    	font-size: 15px;
    	color: $white;
    	font-weight: 700;
    	width: 400px;
    	border-radius: 26px;
    	text-transform: uppercase;
		@include transition(color 0.15s ease-out, background-color 0.15s ease-out);
		border: 1px solid $white;
		margin-top: 50px;
		&:hover {
			text-decoration: none;
			color: $black;
			background-color: $white;
		}
    }
}
.instagram-row-holder {
	width: 100%;
	float: left;
	background: url(../images/texture-bg.jpg) repeat;
	background-position: center center;
	height: 450px;
	overflow: hidden;
	position: relative;
	.instagram-row {

		div:first-child {
			width: 50%;
			background: url(../images/home-instagram-background-2.jpg) no-repeat;

			background-position: center center;
			background-repeat: no-repeat;
		    background-size: cover;
			height: 450px;
			position: relative;
			text-align: center;
			a {
				color: $white;
				text-transform: uppercase;
				display: inline-block;
				line-height: 28px;
				font-size: 15px;
				margin-top: 238px;
				border-bottom: 1px solid $white;
				&:hover {
					text-decoration: none;
				}
				&:before {
					content: ' ';
    				background: url(../images/sprite.png) no-repeat -1px -24px;
    				position: absolute;
    				top: 192px;
    				left: 50%;
    				margin-left: -17px;
    				height: 35px;
    				width: 35px;

				}
			}
		}
		.instagram-images {
			width: calc(50% + 150px);
			position: absolute;
			top: 0;
			left: 50%;
			img {
				float: left;
				display: block;
			}
		}
	}
}
.clients-row-holder, .blog-posts-holder, .google-reviews-holder {
	width: 100%;
	float: left;
	background: url(../images/texture-bg.jpg) repeat;
}
.clients-row, .blog-posts-row {
	width: 1216px;
	background: $white;
	margin: 0 auto;
	border-left: 1px solid $gray;
	border-right: 1px solid $gray;
	.clients-holder {
		padding: 88px 67px;
		.client {
			height: 328px;
			width: 328px;
			margin: 16px
		}
	}
	h3, h4 {
		font-weight: 700;
		font-size: 20px;
		text-transform: capitalize;
		color: $grayText;
		border-bottom: 1px solid $gray;
		padding: 68px 0 22px 38px;
		position: relative;
		a {
			position: absolute;
			right: 62px;
			bottom: 24px;
			font-size: 12px;
			font-weight: 400;
			&:before {
				content: ' ';
				display: inline-block;
				width: 5px;
				height: 8px;
				background: url(../images/sprite.png) no-repeat -98px -138px;
				margin-right: 6px;
			}
		}
	}
	h4 {
		color: #41484F;
		font-size: 36px;
		margin: 0 22px 0 30px;
		padding: 22px 0 34px;
		line-height: 48px;
	}
	h5 {
		color: $grayText;
		font-size: 20px;
		text-align: center;
		text-transform: capitalize;
		padding: 20px;
		border-top: 1px solid $gray;
		padding: 40px 0 30px 0;
	}
	a.button {
		text-transform: uppercase;
		background-color: $gold;
		line-height: 52px;
		display: inline-block;
		padding: 0 42px;
		border-radius: 26px;
		color: $white;
		font-size: 15px;
		text-align: center;
		margin: 30px 0 0 30px;
	}
	.blog-posts-holder {
		width: 1216px;
		.blog-post {
			padding: 56px 72px 48px;
			height: 438px;
			background: $white;
			border-right: 1px solid $gray;
			&:nth-last-child(2) {
				//border-right: 0;
			}
			.date {
				color: $gold;
				font-size: 16px;
			}
			h5 {
				font-size: 20px;
				color: $black;
				border-bottom: 1px solid $gold;
				text-transform: capitalize;
				font-weight: 400;
				padding: 24px 0 16px;
			}
			h6 {
				font-size: 14px;
				color: $black;
				border-bottom: 1px solid $gold;
				text-transform: uppercase;
				font-weight: 400;
				padding: 12px 0 8px;
			}
			.summary {
				color: $black;
				font-size: 16px;
				border-bottom: 1px solid $gold;
				@include secondary-font;
				padding: 20px 0;
				margin-bottom: 20px;
			}
			a {
				font-size: 15px;
				text-transform: uppercase;
				color: $grayText;
				font-weight: 700;
			}
		}
	}
}
.google-reviews-holder {
	background: $white;
	.google-review {
		padding: 56px 60px;
		border-right: 1px solid $gray;
		.review-header {
			font-size: 20px;
			line-height: 24px;
			color: $grayText;
			text-transform: uppercase;
			padding-bottom: 32px;
			margin-bottom: 42px;
			border-bottom: 1px solid $gold;
			span {
				color: $gold;
				display: inline-block;
				border-bottom: 1px solid $gold;
			}
		}
		.name-image {
			color: $black;
			font-size: 13px;
			font-weight: bold;
			img {
				vertical-align: top;
				width: 42px;
				height: 42px;
			}
			span {
				position: relative;
				top: 8px;
				padding-left: 6px;
			}
		}
		.star-rating-relative-time {
			padding-left: 50px;
			margin-top: 4px;
			height: 17px;
			img {
				margin-top: 2px;
				width: 14px;
				height: 13px;
				display: block;
				float: left;
				vertical-align: middle;
			}
			span {
				font-size: 13px;
				color: $grayText;
				position: relative;
				float: left;
				line-height: 17px;
				padding-left: 6px;
			}
		}
		.review {
			padding-left: 50px;
			color: $black;
			font-size: 13px;
			line-height: 18px;
			padding-top: 4px;
			a.more-link {
				color: $blueLink;
			}
		}
		&:last-child {
			border-right: 0;
		}
	}
}
.home-testimonials-holder, .showroom-testimonials-holder {
    background-size: cover;
    width: 100%;
    height: 528px;
    float: left;
    color: $white;
	.testimonials-row {
		width: 860px;
		margin: 0 auto;
		position: relative;
    	height: 528px;
		.testimonial {
			width: 100%;
			height: 490px;
			position: absolute;
			left: 0;
			top: 58px;
			display: none;
			text-align: center;
			opacity: 0;
			&:first-child {
				display: block;
				opacity: 1;
			}
			.content {
		    	@include secondary-font;
		    	height: 300px;
		    	display: table;
				font-size: 31px;
				line-height: 40px;
		    	div {
		    		display: table-cell;
		    		vertical-align: middle;
		    	}
		    }
		    .citation {
		    	font-size: 15px;
		    	text-transform: uppercase;
		    	span {
		    		color: $gold;
		    	}
		    }
		}
		.testimonial-navigation {
			position: absolute;
			bottom: 86px;
			width: 200px;
			left: 50%;
			margin-left: -100px;
			text-align: center;
			.dot {
				height: 8px;
				width: 8px;
				border-radius: 50%;
				background-color: $white;
				margin: 0 5px;
				display: inline-block;
				@include transition(background-color 1.2s linear);
				cursor: pointer;
				&.active {
					background-color: $gold;
				}
			}
		}
		.testimonial-read-more {
			position: absolute;
			bottom: 50px;
			width: 300px;
			left: 50%;
			margin-left: -150px;
			text-decoration: underline;
			text-align:center;
			color: #b3afa6;
		}
	}
}
.showroom-testimonials-holder {
	.testimonials-row {
		.citation {
			margin-top: 12px;
		}
	}
}
.facebook-row {

    background-size: cover;
    width: 100%;
    height: 528px;
    float: left;
    color: $white;
     .columns {
	   	height: 100%;
     	&.facebook-link {
	    	background-color: rgba(35,31,32,0.7);
	    	text-align: center;
	    	a {
	    		color: $white;
				text-transform: uppercase;
				display: inline-block;
				line-height: 28px;
				font-size: 15px;
				margin-top: 238px;
				border-bottom: 1px solid $white;
				&:hover {
					text-decoration: none;
				}
				&:before {
					content: ' ';
					background: url(../images/sprite.png) no-repeat 0 -60px;
					position: absolute;
					top: 192px;
					left: 50%;
					margin-left: -17px;
					height: 35px;
					width: 35px;

				}
	    	}
	     }
    }
}
.video-feature {
	background-size: cover;
    width: 100%;
    height: 528px;
    float: left;
    color: $white;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 40px;
    span {
    	font-weight: bold;
    }
    div.columns {
		margin-top: 190px;
		padding: 0 27%;
    }
    .play-button {
    	display: block;
    	img {
    		width: 46px;
    		height: 46px;
    	}
    }
    a.underline {
    	border-bottom: 1px solid $white;
    	padding-bottom: 4px;
    	&:hover {
    		text-decoration: none;
    	}
    }
}

.olde-wood-home, .olde-wood-showroom {
	background-size: cover;
    width: 100%;
    height: 528px;
    float: left;
    color: $white;
    text-align: center;
    font-size: 18px;
    //text-transform: uppercase;
    line-height: 40px;
    img{
    	max-width: 100%
    }
    div.columns {
		margin-top: 50px;
		padding: 0 20%;
    }
    a.underline {
    	border-bottom: 2px solid $white;
    	padding-bottom: 4px;
    	font-weight: bold;
    	white-space: nowrap;
    	&:hover {
    		text-decoration: none;
    	}
    }
}
.olde-wood-showroom {
	div.columns {
		margin-top: 80px;
	}
}
.clients-row-holder {
	width: 100%;
	float: left;
	background: url(../images/texture-bg.jpg) repeat;
}
.clients-row {
	width: 1216px;
	background: $white;
	margin: 0 auto;
	border-left: 1px solid $gray;
	border-right: 1px solid $gray;
	.clients-holder {
		padding: 67px;
		.client {
			height: 328px;
			width: 328px;
			margin: 16px;
			img {
				max-width: 328px;
				height: auto;
			}
		}
	}
	.key-clients-text {
		font-family: "baskerville-urw",sans-serif;
		font-size: 16px;
		color: #424850;
		border-bottom: 1px solid $gray;
		padding: 38px;
		line-height: 29px;
	}
}

.home-clients-slider-holder {
	position: relative;
	padding-bottom: 30px;
	border-bottom: 1px solid $gray;
}

.home-clients-slider {
	padding: 0 127px;
}
.home-clients-slide-image {
	outline: none !important;
	padding: 0 10px;
}
.home-clients-slider-navigation-left, .home-clients-slider-navigation-right {
	position:absolute;
	top: 50%;
	margin-top: -38px;
	background-position: -80px -114px;
	width: 10px;
	height: 16px;
	left: 60px;
	cursor: pointer;
}
.home-clients-slider-navigation-right  {
	background-position: -92px -114px;
	right: 60px;
	left: auto;
}

@include queryWidth(max, 1400px) {
	.home-showrooms-holder {
		.home-showrooms {
			width: 90%;
			.home-showroom {
				padding: 38px 16px;
			}
		}
	}
	.nationwide-row {
		.columns {
			div {
				height: auto;
				min-height: 0;
			}
		}
	}
	.clients-row {
		width: 90%;
		h4 {
			margin: 22px 10% 0 0;
  			padding: 0 0 22px;
		}
		a.button {
			padding: 0;
			margin: 0;
			width: 90%;
			margin: 30px 0 0 0;
		}
		.clients-holder {
			padding: 40px;
			.client {
				width: 30.33333%;
				margin: 1.5%;
				height: auto;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
	.clients-row {
		.blog-posts-holder {
			width: 100%;
			.blog-post {
			}
		}
	}
	.home-clients-slide-image {
		img {
			max-width:100%;
			height: auto;
		}
	}
}
.home-showrooms-holder {
	.home-showrooms {
		.showrooms-flex-holder {
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}
}
@include queryWidth(max, 1200px) {

	.home-showrooms-holder {
		.home-showrooms {
			border-right: 1px solid #bab9b7;
			h2 {
				border-left: 0;
			}
			.showrooms-flex-holder {
				display: block;
				.home-showroom {
					width: 33.333333333%;
					flex: none;
					border-bottom: 1px solid $gray;
					border-right: 1px solid #bab9b7;
					border-left: 0;
					min-height: 320px;
					&:nth-child(3) {
						border-right: 0;
					}
					h3 {
						font-size: 16px;
					}
				}
			}
		}
	}
	.nationwide-row {
		.columns {
			padding-left: 70px;
			padding-right: 70px;
		}
	}
	.clients-row {
		h4 {
			margin: 0 5% 0 0;
  			padding: 0 0 12px;
		}
		a.button {
			padding: 0;
			margin: 0;
			width: 95%;
			margin: 20px 0 0 0;
		}
	}
	.clients-row {
		.blog-posts-holder {
			.blog-post {
				padding: 40px;
			}
		}
	}
	.video-feature {
		div.columns {
			padding: 0 20%;
		}
	}
	.olde-wood-home {
		height: 600px;
	}
	.google-reviews-holder {
		.google-review {
			padding: 40px;
		}
	}
}

@include tablet {
	.home-showrooms-holder {
		.home-showrooms {
			border-right: 1px solid #bab9b7;
			h2 {
				border-left: 0;
			}
			.showrooms-flex-holder {
				display: block;
				.home-showroom {
					width: 33.333333333%;
					flex: none;
					border-bottom: 1px solid $gray;
					border-right: 1px solid #bab9b7;
					border-left: 0;
					min-height: 320px;
					&:nth-child(3) {
						border-right: 0;
					}
				}
			}
		}
	}
	.nationwide-row {
		.background {
			display: none;
		}
		.columns:last-child {
			background: $gold;
		}
	}
	.instagram-row-holder {
		height: 932px;
		.instagram-row {
			div:first-child {
				width: 100%;
				background-position: center center;
    			background-size: cover;
			}
			.instagram-images {
				position: absolute;
				float: left;
				width: calc(100% + 467px);
				left: 0;
				top: 466px;
			}
		}
	}
	.clients-row {
		.clients-holder {
			.client {
				max-width: 328px;
				min-width: 47%;
				.client-name {
					text-transform: uppercase;
					color: #41484F;
					font-size: 20px;
					border-bottom: 1px solid $gold;
					padding: 36px 0 18px 0;
				}
				.project-description {
					color: #41484F;
					font-size: 15px;
					padding: 22px 0;
					@include secondary-font;
					-webkit-font-smoothing: subpixel-antialiased;
					max-height: 62px;
					overflow: hidden;
					&.showroom-title {
						padding-bottom: 10px;
						width: 100%;
					}
				}
				.email {
					color: #41484F;
					font-size: 12px;
					@include secondary-font;
					-webkit-font-smoothing: subpixel-antialiased;
					max-height: 62px;
					overflow: hidden;
					width: 100%;
				}
				a.button {
					display: none;
				}
			}
		}
		h4 {

		}
	}
	.home-testimonials-holder {
		.testimonials-row {
			width: 90%;
			margin: 0 5%;
			.testimonial {
				top: 38px;
				.content {
					height: 340px;
				}
				.citation {
					color: #A9AAAB;
					span {
						color: $white;
					}
				}
			}
			.navigation {
				bottom: 84px;
			}
		}
	}

	.clients-row {
		.blog-posts-holder {
			.blog-post {
				height: auto;
				border-top: 1px solid $gray;
				&:first-child {
					border-top: 0;
				}
			}
		}
	}
	.google-reviews-holder {
		padding: 20px 0;
		.google-review {
			border-right: 0;
			padding: 40px 40px;
			border-bottom: 1px solid $gray;
		}
	}
}

@include queryWidth(max, 800px) {
	.nationwide-row {
		height: auto;
		.columns {
			padding-top: 34px;
		}
		.button {
			margin-top: 40px;
			margin-bottom: 40px;
		}
	}
	.olde-wood-home {
		height: 620px;
	}
}

@include mobile {
	.home-showrooms-holder {
		.home-showrooms {
			width: 100%;
			border: 0;
			margin-bottom: 40px;
			h2 {
				border-left: 0;
				font-size: 16px;
				padding: 26px 0 22px 38px;
				line-height: 24px;
			}
			.showrooms-flex-holder {
				.home-showroom {
					width: 90%;
					height: auto;
					min-height: 0;
					padding: 0 5px;
					margin: 0 5%;
					border-right: 0;
					h3 {
						margin-bottom: 0;
						line-height: 50px;
						line-height: 22px;
						padding: 14px 0;
						font-weight: 400;
					}
				}
			}
		}
	}
	.nationwide-row {
		.columns {
			padding: 48px 10px 0 10px;
			padding-left: 20px;
			padding-right: 20px;
			h2 {
				font-size: 18px;
				line-height: 24px;
				padding-bottom: 14px;
			}
		}
		.button {
			font-size: 12px;
			padding: 0 20px;
			width: 300px;
			margin-left: 10px;
			margin-right: 10px;
		}
	}
	.instagram-row-holder {
		height: 466px;
	}
	.clients-row {
		width: 100%;
		border-left: 0;
		border-right: 0;
		h3 {
			font-size: 16px;
			padding: 38px 0 36px 40px;
		}
		.clients-holder {
			padding-top: 20px;
			padding-bottom: 20px;
			.client {
				margin-left: 3.125%;
				margin-right: 3.125%;
				width: 93.75%;
				max-width: none;
				border-bottom: 1px solid $gold;
				padding-bottom: 22px;
				padding-top: 12px;
				&:first-child {
					width: 93.75%;
				}
				&:nth-last-child(2) {
					border-bottom: 0;
				}
				img {
					width: 32%;
					float:left;
					display: block;
				}
				.client-name {
					border-bottom: 0;
					padding-left: 20px;
					display: inline-block;
					padding: 0;
					float: left;
					width: 68%;
					padding-left: 20px;
				}
			}
		}
	}
	.home-testimonials-holder {
		.testimonials-row {
			.testimonial {
				.content {
					height: 340px;
					font-size: 19px;
				}
			}
			.testimonial-navigation {
				bottom: 84px;
			}
		}
	}
	.facebook-row {
		height: 320px;
		.columns.facebook-link a {
			margin-top: 128px;
			&:before {
				top: 174px;
			}
		}
	}
	.home-clients-slider {
		padding: 0 40px;
		text-align: center;
		.home-clients-slide-image {
			text-align: center;
			a {
				text-align: center;
				img {
					display: inline-block;
				}
			}
		}
	}
	.home-clients-slider-navigation-right {
		right: 20px;
	}
	.home-clients-slider-navigation-left {
		left: 20px;
	}
	.video-feature {
		div.columns {
			padding: 0 20px;
			margin-top: 160px;
		}
	}
	.olde-wood-home, .olde-wood-showroom {
    	font-size: 16px;
    	div.columns {
    		margin-top: 50px;
    		padding: 0 10%;
    	}
	}
}


