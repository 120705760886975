.reviews-textured-holder {
	.inner {
		border-right: 0;
		border-left: 0;
		position: relative;
		&:before {
			width: 4px;
			height: 100%;
			top: 0;
			right: -1px;
			background: $white;
			content: '';
			position: absolute;
			z-index: 2;
		}
		&:after {
			width: 1px;
			height: 100%;
			top: 0;
			right: 1px;
			background: $gray;
			content: '';
			position: absolute;
			z-index: 3;
		}
		.reviews-header {
			.header-section {
				border-left: 1px solid $gray;
			}
			h2 {
				font-weight: 700;
			    font-size: 20px;
			    text-transform: capitalize;
			    color: #60646D;
			    padding: 68px 0 22px 38px;
			    width: 50%;
			    border-bottom: 0;
			}
		}
		.filter-trigger {
			width: 50%;
			text-align: right;
			padding-right: 14px;
			padding-top: 38px;
		}
		.button {
			margin-top:20px;
			text-transform: uppercase;
			background-color: $white;
			line-height: 40px;
			display: inline-block;
			padding: 0 42px;
			border-radius: 20px;
			color: #424850;
			font-size: 13px;
			text-align: center;
			border: 1px solid $grayText;
			@include transition(color 0.15s ease-out, background-color 0.15s ease-out);
			&:hover, &.open {
				text-decoration: none;
				color: $white;
				border: 0;
				background-color: $gold;
				border: 1px solid $gold;
				font-weight: 700;
			}
		}
		#reviews-filter-holder {
			text-align: center;
			padding: 32px;
			border-bottom: 1px solid $gray;
			display: none;
			border-left: 1px solid $gray;
			&.open {
				display: block;

				border-top: 1px solid $gray;
			}
			.check-label-holder {
				display: inline-block;
			}
			label {
				color: #b3afa7;
				font-size: 16px;
				display: block;
				cursor: pointer;
				text-transform: uppercase;
				margin-right: 44px;
			}
			input[type=checkbox] {
				position: absolute;
				top: -9999px;
				left: -9999px;
				display: none;
			}
			input[type=checkbox] ~ label::before {
				content: ' ';
				display: inline-block;
				line-height: 16px;
				width: 16px;
				height: 16px;
				border: 1px solid #b3afa7;
				vertical-align: middle;
				margin-right: 18px;
				position: relative;
				top: -1px;
			}
			input[type=checkbox]:checked ~ label::before {
				background-color: #61656E;
			}
		}
		.filter-apply-holder {
			text-align: center;
			display: none;
			border-bottom: 1px solid $gray;
			border-left: 1px solid $gray;
			&.open {
				display: block;
			}
			a {
				border-color: $tan;
				color: #424850;
				margin-bottom: 20px;
			}
		}
		.reviews-holder {
			background: url(../images/texture-bg.jpg) repeat;
			width: calc(100% + 1px);
			margin: 0 0 0 1px;
			position: relative;
			.review {
				background: $white;
				width: calc( (100% + 2px) / 3 );
				padding: 56px 60px;
				border: 1px solid $gray;
				margin: -1px 0 0 -1px;
				.review-header {
					font-size: 20px;
					line-height: 24px;
					color: $grayText;
					text-transform: uppercase;
					padding-bottom: 32px;
					margin-bottom: 42px;
					border-bottom: 1px solid $gold;
					span {
						color: $gold;
						display: inline-block;
						border-bottom: 1px solid $gold;
					}
				}
				.name-image {
					color: $black;
					font-size: 13px;
					font-weight: bold;
					img {
						vertical-align: top;
						width: 42px;
						height: 42px;
					}
					span {
						position: relative;
						top: 8px;
						padding-left: 6px;
					}
				}
				.star-rating-relative-time {
					padding-left: 50px;
					margin-top: 4px;
					height: 17px;
					img {
						margin-top: 2px;
						width: 14px; 
						height: 13px;
						display: block;
						float: left;
						vertical-align: middle;
					}
					span {
						font-size: 13px;
						color: $grayText;
						position: relative;
						float: left;
						line-height: 17px;
						padding-left: 6px;
					}
				}
				.review-content {
					padding-left: 50px;
					color: $black;
					font-size: 13px;
					line-height: 18px;
					padding-top: 4px;
					a.more-link {
						color: $blueLink;
					}
				}
				&.link-box {
					text-transform: capitalize; 
					text-align: center;
					font-size: 20px;
					line-height: 24px;
					color: $grayText;
					padding-left: 40px;
					padding-right: 40px;
					padding-top: 110px; 
					span .showroom{
						color: $gold;
						display: inline-block;
						font-weight: bold;
					}
					img {
						display: block;
						margin: 0 auto 26px;
						width: 41px;
						height: 41px;
					}
					a.button {
						font-size: 15px;
						background: $gold;
						color: $white;
						margin-top: 30px;
						padding-left: 30px;
						padding-right: 30px;
						span {
							font-size: 24px;
							display: inline-block;
							vertical-align: middle;
							padding-left: 6px;
							font-weight: 300;

						}
						&:hover {
							text-decoration: none;
							color: $grayText;
							border: 1px solid $grayText;
							background-color: $white;
						}
					}
				}
			}
		}
		.no-reviews {
			text-align: center;
			width: 100%;
			padding: 84px;
			font-size: 16px;
			color: #61656E;
			text-transform: uppercase;
			width: 100%;
			a {
				text-decoration: underline;
			}
		}

	}
}
@include tablet {
	.reviews-textured-holder {
		.inner {
			#reviews-filter-holder {
				label {
					margin-right: 24px;
				}
			}
			.reviews-holder {
				.review {
					width: 50%;
				}
			}
		}
	}

}

@include queryWidth(max, 920px) {
	.reviews-textured-holder {
		.inner {
			#reviews-filter-holder {
				text-align: left;
				.check-label-holder {
					display: block;
					margin: 6px 0;
				}
				label {
					margin-right: 0;
				}
			}
		}
	}
}
@include mobile {
	.reviews-textured-holder {
		.inner {
			&:before, &:after {
				display: none;
			}
			.reviews-header {
				h2 {
					width: 60%;
				}
				.filter-trigger {
					width: 40%;
				}
			}
			.reviews-holder {
				.review {
					padding: 40px;
					width: calc(100% + 1px);
					&.link-box {
						padding-top: 40px;
					}
				}
			}
		}
	}
}
@include queryWidth(max, 540px) {
	.reviews-textured-holder {
		.inner {
			.reviews-header {
				h2 {
					width: 100%;
				}
				.filter-trigger {
					width: 100%;
					text-align: left;
					padding: 0 0 22px 38px;
					a {
						margin-top: 10px;
					}
				}
			}
		}
	}
}