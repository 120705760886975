.product-categories-holder, .showrooms-holder {
	.product-categories, .showrooms {
		//margin-bottom: 84px;
		.spacer {
			height: 84px;
			width: 100%;
		}
		.product-category, .showroom {
			padding-bottom: 25px;
			border-bottom: 1px solid $gray;
			text-align: center;
			padding-right: 1px;
			&:nth-of-type(odd) {
				border-right: 1px solid $gray;
				padding-right: 0;
			}
			img {
				max-width: 91.1763%;
				height: auto;
				margin: 4.4164%;
				height: auto;
				opacity: 0.85;
				@include transition(margin 0.3s ease,opacity 0.3s ease,max-width 0.3s ease);
			}
			h3 {
				font-weight: 400;
				font-size: 20px;
				text-transform: capitalize;
				color: $grayText;
				margin-top: 14px;
				margin-bottom: 18px;
				text-align: left;
				padding: 0 25px;
				span {
					font-weight: 400;
					color: $gold;
					font-size: 20px;
					@include secondary-font;
					-webkit-font-smoothing: subpixel-antialiased;
					text-transform: none;
				}

			}
			span.mobile-location  {
				text-align: left;
				font-size: 16px;
				color: $gold;
				display: inline-block;
				width: 100%;
				padding: 0 25px;
				text-transform: uppercase;
				min-height: 40px;
			}
			.location  {
				text-align: left;
				font-size: 16px;
				color: $gold;
				display: inline-block;
				width: 100%;
				padding: 0 25px;
				text-transform: uppercase;
				min-height: 40px;
				&:before {
					content: ' ';
					background: url(../images/sprite.png) no-repeat -1px -1px;
					width: 11px;
					height: 16px;
					display: inline-block;
					margin-right: 10px;
					position: relative;
					top: 2px;
				}
				&.mobile-location {
					&:before {
						opacity: 0;
						width: 0;
						margin-right: 0;
					}
				}
			}
			&:hover {
				text-decoration: none;
				border-bottom: 10px solid $gold !important;
				padding-bottom: 16px;
				@include box-shadow(0 0 8px rgba(0, 0, 0, 0.2),0 8px 16px rgba(0, 0, 0, 0.4));
				img {
					opacity: 1;
					margin: 0 0 2.38811% 0;
					max-width: 100%;
				}
				h3 {
					font-weight: 700;
					span {
						font-weight: 400;
					}
					&:after {
						content: ' ';
		  				background: url(../images/sprite.png) no-repeat -216px -3px;
		  				width: 49px;
		  				height: 16px;
		  				display: inline-block;
		  				top: 0px;
		  				position: relative;
		  				margin-left: 22px;
					}
				}
			}
		}
	}
}
.showrooms-holder {
	.showrooms {
		border-bottom: 1px solid $gray;
		margin-bottom: 0;
		.showroom {
			h3 {
				margin-bottom: 12px;
			}
			&:nth-last-child(2) {
				border-bottom: 0;
			}
		}
	}
}
.products-holder {
	.product {
		text-align: center;
		border-right: 1px solid $gray;
		border-bottom: 1px solid $gray;
		padding: 20px;
		@include transition(box-shadow 0.3s ease);
		&:nth-child(3n+3) {
			border-right: 0;
		}
		h3 {
			margin-top: 20px;
			font-size: 14px;
			font-weight: 400;
			color: #61656E;
			min-height: 38px;
		}
		img {
			max-width: 100%;
			opacity: 0.85;
			@include transition(opacity 0.3s ease);
		}
		&:hover {
			text-decoration: none;
			border-bottom: 5px solid $gold;
			padding-bottom: 16px;
			@include box-shadow(0 0 8px rgba(0, 0, 0, 0.2),0 8px 16px rgba(0, 0, 0, 0.4));
			img {
				opacity: 1;
			}
		}
	}
	.spacer {
		height: 115px;
		border-top: 1px solid $gray;
		position: relative;
		top: -1px;
		width: 100%;
		&.with-category-content {
			height: 10px;
		}
	}
	.no-products {
		text-align: center;
		padding: 120px 0;
		h2 {
			color: #60646D;
			text-transform: uppercase;
			font-size: 20px;
			border: 0;
			padding: 0;
		}
	}
	.product-category-video-holder {
		border-top: 1px solid #bab9b7;
		position: relative;
		top: -1px;
		padding-bottom: 40px;
		h3 {
			font-size: 20px;
	    	text-transform: uppercase;
	    	color: #60646D;
	    	text-align: center;
	    	margin: 40px 0 20px;
		}
	}
}
.products-loader-holder {
	text-align: center;
	min-height: 240px;
	display: none;
	.inner {
		padding: 120px 0;
	}

}
.products-category-filter-header {
	width: 100%;
	float: left;
	height: 82px;
	border-bottom: 1px solid $gray;
	.product-category-filter {
		text-align: right;
		padding-right: 14px;
		a.button {
			margin-top:20px;
		}
	}
}

.product-categories {
	.button {
		margin-top:20px;
		text-transform: uppercase;
		background-color: $gold;
		line-height: 40px;
		display: inline-block;
		padding: 0 42px;
		border-radius: 20px;
		color: #424850;
		font-size: 13px;
		text-align: center;
		color: $white;
		border: 1px solid $gold;
		@include transition(color 0.15s ease-out, background-color 0.15s ease-out);
		&:hover, &.open {
			text-decoration: none;
			color: $gold;
			border: 0;
			background-color: $white;
			font-weight: 700;
		}
	}
	&.mantels {
		.button {
			display: inline-block;
			color: $white;
			background-color: $gold;
			font-size: 15px;
			text-transform: uppercase;
			border-radius: 25px;
			height: 50px;
			padding: 0 60px;
			line-height: 50px;
			@include transition(color 0.15s ease-out, background-color 0.15s ease-out);
			border: 0;
			&:hover {
				text-decoration: none;
				color: $grayText;
				border: 1px solid $grayText;
				background-color: $white;
				font-weight: 700;
			}
			&:before {
				content: '';
				display: none;
			}
		}
		.mantels-subcategory-header {
			border-bottom: 1px solid $gray;
			padding-right: 50px;
			h2 {
				width: 50%;
				float:left;
				border: 0;
			}
			.select-style {
				float:right;
			    overflow: hidden;
			    position: relative;
			    margin-top: 68px;
			    &:before {
			    	content: '';
			    	display: block;
			    	width: 10px;
			    	height: 7px;
			    	position: absolute;
			    	top: 7px;
			    	left: 10px;
			    	background: url("../images/icon-select-alt.jpg") no-repeat;
			    }
			    select {
			    	@include primary-font;
			    	text-transform: uppercase;
			    	margin-bottom: 0;
				    padding: 0 12px 0 38px;
				    border: none;
				    box-shadow: none;
				    background: transparent;
				    background-image: none;
				    -webkit-appearance: none;
				    color: #60646D;
				    font-size: 12px;
				    &:focus {
				    	outline: 0;
				    }
				}
			}
		}
		.no-category-products-message {
			padding: 0;
			h2 {
				font-size: 16px !important;
				padding-top: 38px !important;
			}
		}
		.products-holder {
			.products {
				background: #fff;
				.product {
					border-right: 0;
					text-align: center;
				}
			}
		}
		
		.spacer {
			border-top: 0;
	 		height: 115px; 		
			&.with-category-content {
				height: 10px;
			}
	 	}
	}
}
.no-products-message, .no-category-products-message {
	display: none;
}
.no-products-message.textured-holder .inner div, .no-category-products-message {
	text-align: center;
	padding: 120px 0;
	h2 {
		color: #60646D;
		text-transform: uppercase;
		font-size: 20px;
		border: 0;
		padding: 0;
		border-bottom: 0;
	}

}
@include tablet {
	.product-categories-holder, .showrooms-holder {
		.product-categories, .showrooms {
			.product-category, .showroom {
				padding: 14px;
				&:nth-of-type(odd) {
					border-right: 1px solid $gray;
					padding-right: 13px;
				}
				h3 {
					font-size: 12px;	
					margin-top: 12px;
					margin-bottom: 5px;
					font-size: 12px;
					span {
						font-size: 12px;
					}
				}
				.location, span.mobile-location {
					font-size: 12px;
					min-height: 32px;
				}
				&:hover {
					border-bottom: 5px solid $gold;
					padding-bottom: 5px;
					h3 {
						&:after {
							height: 10px;
							background-position: -267px -5px;
							width: 26px;
							margin-left: 12px;
						}	
					}
				}
			}
		}
	}
	.products-holder {
		.product {
			&:nth-child(3n+3) {
				border-right: 1px solid $gray;
			}
			&:nth-child(2n+2) {
				border-right: 0;
			}
			img {
				opacity: 1;
			}
		}
	}
}
@include mobile {
	.product-categories-holder, .showrooms-holder {
		.product-categories, .showrooms {
			.product-category, .showroom {
				padding: 10px 20px 0 18px;
				border-bottom: 0;
				border: 0;
				img {
					width: 100%;
					max-width: 607px;
					height: auto;
					margin: 0;
					opacity: 1;
				}
				&:nth-child(odd) {
					border: 0;
					padding-right: 18px;
				}
				h3 {
					font-weight: 400;
					border-bottom: 3px solid $gold;
					padding-bottom: 10px;
				}
				&:first-child {
					padding-top: 20px;
				}
				&:hover {
					font-weight: 400;
					border-bottom: 0 !important;
					padding-bottom: 0;
					@include box-shadow(0 0 0 $white, 0 0 0 $white);
					img {
						width: 100%;
						max-width: 607px;
						height: auto;
						margin: 0;
					}
				}
			}
		}
	}

	.products-holder {
		.product {
			border-right: 0;
			border-left: 0;
			&:nth-child(3n+3) {
				border-right: 0;
			}
			&:nth-child(2n+2) {
				border-right: 0;
			}
		}
	}
	.product-categories.mantels {
		.mantels-subcategory-header {
			padding-right: 20px;
			h2 {
				width: 100%;
			}
			.select-holder {
				text-align:right;
				.select-style {
					margin-top: 20px;
					width: auto;
					display: inline-block;
				}
			}

		}
	}
	.showrooms-holder {
		.showrooms {
			margin-bottom: 40px;
			border-bottom: 0;
			.showroom {
				.location {
					font-size: 12px;
					padding-bottom: 10px;
				}
				h3 {
					span {
						font-size: 12px;
					}
				}
			}
			.showroom.no-location {
				h3 {
					border-bottom: 0;
					padding-bottom:0;
				}
				.location {
					border-bottom: 3px solid $gold;
				}
			}
		}
	}
}