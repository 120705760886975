#inspirations {
	.button {
		text-transform: uppercase;
		background-color: $white;
		line-height: 40px;
		display: inline-block;
		padding: 0 42px;
		border-radius: 20px;
		color: #424850;
		font-size: 13px;
		text-align: center;
		border: 1px solid $grayText;
		@include transition(color 0.15s ease-out, background-color 0.15s ease-out);
		&:hover, &.open {
			text-decoration: none;
			color: $white;
			border: 0;
			background-color: $gold;
			border: 1px solid $gold;
			font-weight: 700;
		}
	}
	.inner {
		border-left: 0;
		border-right: 0;
		width: 1217px;
	}
	.inspiration-header {
		width: 100%;
		float: left;
		height: 114px;
		border-left: 1px solid $gray;
		border-right: 1px solid $gray;
		h1 {
			font-size: 20px;
			text-transform: capitalize;
			color: #61656E;
			padding-top: 66px;
			padding-left: 26px;
			border-bottom: 0;
		}
		.inspiration-filter {
			text-align: right;
			padding-right: 14px;
			a.button {
				margin-top: 54px;
			}
		}
	}
	.no-inspiration {
		text-align: center;
		width: 100%;
		float: left;
		padding: 84px;
		font-size: 16px;
		color: #61656E;
		text-transform: capitalize;
		border-top: 1px solid $gray;
		border-left: 1px solid $gray;
		border-right: 1px solid $gray;
		a {
			text-decoration: underline;
		}
	}
	
	.inspiration-images-holder {
		width: calc(100% + 1px);
		position: relative;
		float: left;
		.inspiration-sizer {
			width: 50%;
		}
		.inspiration {
			padding: 22px;
			border: 1px solid $gray;
			margin: -1px -1px 0 0;
			img {
				max-width: 100%;
				height: auto;
				padding-bottom: 20px;
			}
			.featured-product, .location, .title, .reference-number {
				color: #424850;
				font-size: 14px;
				margin: 8px 0;;
				span {
					text-transform: uppercase;
					color: $gold;
				}
			}
			.location {
				span {
					&:before {
						content: ' ';
						background: url(../images/sprite.png) no-repeat -1px -1px;
						width: 11px;
						height: 16px;
						display: inline-block;
						margin-right: 8px;
						position: relative;
						top: 2px;
					}
				}
			}
			.reference-number {
				font-size: 11px;
				height: 14px;
				margin-bottom: 0;
			}
		}
		&:before, &:after {
			content: ' ';
			display: block;
			height: 100%;
			background: $gray;
			position: absolute;
			top: 0;
			width: 1px;
			height: 100%;
		}
		&:before {
			left: 0;
		}
		&:after {
			right: 1px;
		}
	}
	.load-more-blog-posts {
		border-top: 1px solid $gray;
		margin-top: -1px;
		border-left: 1px solid $gray;
		border-right: 1px solid $gray;
	}
}

#inspiration-filter-holder, #products-filter-holder {
	width: 100%;
	float: left;
	border-top: 1px solid $gray;
	border-left: 1px solid $gray;
	border-right: 1px solid $gray;
	display: none;
	&.open {
		display: block;
	}
	.columns {
		padding: 18px 28px;
		width: calc(50% - 1px);
		.checkbox-holder {
			margin: 10px 0;
			&.subtype {
				margin-left: 30px;
			}
		}
		.filter-title {
			font-size: 12px;
			color: #61656E;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 15px;
		}
		label, .flooring-subcategory-anchor {
			color: #b3afa7;
			font-size: 12px;
			display: block;
			cursor: pointer;
		}
		input[type=checkbox] {
			position: absolute;
			top: -9999px;
			left: -9999px;
			display: none;
		}
		input[type=checkbox] ~ label::before {
			content: ' ';
			display: inline-block;
			line-height: 14px;
			width: 14px;
			height: 14px;
			border: 1px solid #b3afa7;
			vertical-align: middle;
			margin-right: 10px;
			position: relative;
			top: -1px;
		}
		input[type=checkbox]:checked ~ label::before {
			background-color: #61656E;
		}
	}
	.columns:first-child {
		border-right: 1px solid $gray;
		width: calc(50% + 1px);
	}
	.inspiration-filter-submit {
		border-top: 1px solid $gray;
		width: 100%;
		height: 64px;
		text-align: center;
		float: left;
		font-size: 12px;
		.button {
			border-color: $gray;
			margin-top: 10px;
			padding: 0 120px;
		}
	}

}

#products-filter-holder {
	border-top: 0;
	border-left: 0;
	border-top: 0;
	border-bottom: 1px solid $gray;
	.button {
		border-color: $gray;
		margin-top: 10px;
		padding: 0 40px;
	}
	.columns:first-child {
		border-right: 0;
	}
	.columns:nth-child(2) {
		border-left: 1px solid $gray;
		a.button {
			margin-left: 12px;
		}
	}
}

@media only screen and (max-width: 1400px) {
	#inspirations {
		.inner {
			width: 90%;
		}
	}
}

@include tablet {
	#inspirations {
		.inspiration-images-holder {
			.inspiration {
				.featured-product, .location, .title {
					font-size: 12px;
				}
			}
		}
	}
}

@include mobile {
	#inspirations {
		.inspiration-images-holder {
			.inspiration-sizer {
				width: 100%;
			}
			.inspiration {
				.reference-number {
					height: auto;
				}
			}
		}
		.inspiration-header {
			height: auto;
			h1 {
				padding: 24px;
			}
			.inspiration-filter {
				text-align: center;
				padding-right: 0;
				border-bottom: 1px solid $gray;
				a.button {
					margin: 18px 0;
				}
			}
		}
	}
	#inspiration-filter-holder, #products-filter-holder {
		border-right: 0;
		.columns {
			width: 100%;
			border-left: 0;
		}
		.columns:first-child { 
			width: 100%;
			border-bottom: 1px solid $gray;
		}
	}
}
@media only screen and (max-width: 360px) {
	#inspiration-filter-holder, #products-filter-holder {
		.button {
			padding: 0 30px;
		}
	}
}