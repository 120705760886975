@import '../node_modules/sass-mixins/mixins';
@import 'partials/reset';
@import 'partials/media-queries';
@import 'partials/fonts';
@import 'partials/colors';

html {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0;
}

*, *:before, *:after {
  @include box-sizing;
}

body {
	@include primary-font;
	-webkit-font-smoothing: antialiased;
}

.wrapper {
	max-width: 1400px;
	margin: 0 auto;

	.interior-page & {
		max-width: 905px;
	}

	.bottom & {
		margin-bottom: 54px;
	}
}

a {
	color: inherit;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.clear {
	clear: both;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.column {
	position: relative;
	float: left;
}

.sprite {
	background: url(../images/sprite.png) no-repeat;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
 only screen and (-o-min-device-pixel-ratio: 3/2),
 only screen and (min--moz-device-pixel-ratio: 1.5),
     only screen and (min-device-pixel-ratio: 1.5) {
	.sprite {
		background: url(../images/sprite@2x.png) no-repeat;
		background-size: 320px 320px;
	}
}

.textured-holder {
	float: left;
	width: 100%;
	background: url(../images/texture-bg.jpg) repeat;
	.inner {
		background: $white;
		width: 1216px;
		margin: 0 auto;
		border-left: 1px solid $gray;
		border-right: 1px solid $gray;
		h2, h1 {
			font-weight: 700;
			font-size: 20px;
			text-transform: capitalize;
			color: $grayText;
			border-bottom: 1px solid $gray;
			border-top: 1px solid $gray;
			padding: 68px 0 22px 38px;
			position: relative;
			top: -1px;
		}
		h1 {
			font-size: 24px;
		}
	}
	&:first-child {
		.inner {
			h2 {
				top: 0;
				border-top: 0;
			}
		}
	}
}
.iris-menu.iris-menu-open {
	z-index: 101;
}
.button.gold {
	display: inline-block;
	color: $white;
	background-color: $gold;
	font-size: 15px;
	text-transform: uppercase;
	border-radius: 25px;
	height: 50px;
	padding: 0 60px;
	line-height: 50px;
	@include transition(color 0.15s ease-out, background-color 0.15s ease-out);
	border: 0;
	width: auto;
	&:hover {
		text-decoration: none;
		color: $grayText;
		border: 1px solid $grayText;
		background-color: $white;
		font-weight: 700;
	}

	&:not(.no-plus) {
		&:before {
			content: '+';
			font-size: 22px;
			display: inline-block;
			padding-right: 16px;
			vertical-align: middle;
		}
	}
}

.hidden {
	display: none;
}

@include queryWidth(max, 1400px) {
	.textured-holder {
		.inner {
			width: 90%;
		}
	}
}

@include tablet {


}

@include mobile {
	.textured-holder {
		.inner {
			width: 100%;
			border: 0;
			h2 {
				border-left: 0;
				font-size: 16px;
				padding: 26px 0 22px 18px;
				line-height: 24px;
			}
		}
	}
}

.error-page {
	min-height: 500px;
	float: left;
	width: 100%;
	h1 {
		padding: 60px 40px;
	}
}
.button {
	width: 250px;
	height: 53px;
	display: block;
	color: #fff;
	text-align: center;
	line-height: 53px;
	font-size: 13px;
	font-weight: 300;
	font-family: 'Open Sans', sans-serif;
	letter-spacing: 1px;
	text-transform: initial;
	cursor: pointer;
	border: 1px solid transparent;
	@include border-radius(47px);
	span {
		margin-right: 25px;
		display: inline-block;
	}
	img.right-arrow {
		width: 21px;
		position: absolute;
		margin-top: 21px;
		margin-left: 10px;
	}
	img.left-arrow {
		width: 6px;
		margin-right: 10px;
		@include transition(all 0.2s ease-in);
	}
	&.black {
		background: #000;
		@include transition(all 0.2s linear);
		&:hover {
			background: #3A3A3A;
			img {
				//-webkit-filter: brightness(0.40);
				//filter: brightness(0.40);
			}
		}
	}
	&.orange{
		font-family: montserrat, sans-serif;
		font-size:15px;
		text-transform: uppercase;
		background-color:#8d8146;
		color:#FFF;
	}

}

@import 'partials/column-layout';
@import 'partials/column-layout-tablet';
@import 'partials/column-layout-mobile';
@import 'partials/header';
@import 'partials/home';
@import 'partials/product-details';
@import 'partials/products';
@import 'partials/in-stock';
@import 'partials/interior';
@import 'partials/contact';
@import 'partials/showrooms';
@import 'partials/blog';
@import 'partials/inspiration';
@import 'partials/testimonials';
@import 'partials/reviews';
@import 'partials/search';
@import 'partials/cart';
@import 'partials/checkout';
@import 'partials/holiday-popup';
@import 'partials/footer';
@import 'partials/courses';
