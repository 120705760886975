.checkout {
	color: #61656E;
	.columns.large-6 {
		padding-left: 32px;
	}
	.inner.flex {
		display: flex;
	}

	.error-message {
		color: #cc0000;
	  	text-transform: uppercase;
	  	font-size: 13px;
	  	padding-top: 12px;
	}
	.checkout-header {
		width: 100%;
		float: left;
		font-size: 14px;
		text-transform: uppercase;
		line-height: 56px;
		height: 56px;
		border-bottom: 1px solid $gray;
		color: $gold;
		.wrapper {
			width: 1216px;
		}
	}
	.confirmation-header {
		width: 100%;
		float: left;
		border-bottom: 1px solid #bab9b7;
		padding: 20px 0;
		h1 {
			font-weight: 700;
		  	font-size: 20px;
		  	text-transform: uppercase;
		  	color: #60646D;
		  	margin: 10px 0 0 0;
		}
		.wrapper {
			width: 1216px;
		}
		.columns.button-holder {
			text-align: right;
		}
	}
	.address-form-holder {
		padding: 32px;
		border-right: 1px solid $gray;

		.form-message {
			padding: 12px;
			background: #a60000;
			color: #fff;
			font-weight: bold;
			position: relative;
			margin-bottom: 34px;

			&:after {
				content: "!";
				font-size: 2em;
				position: absolute;
				right: 5px;
				top: 2px;
			}
		}
		input[type="text"], input[type="password"], input[type="email"], select {
			border: 1px solid $gray;
			width: 100%;
			line-height: 48px;
			height: 50px;
			@include secondary-font;
			padding: 0 16px;
			font-size: 15px;
			-webkit-font-smoothing: subpixel-antialiased;
			margin-bottom: 34px;
			&::-webkit-input-placeholder {
				color: #61656E;
			}

			&:-moz-placeholder { /* Firefox 18- */
			   	color: #61656E;
			}

			&::-moz-placeholder {  /* Firefox 19+ */
			   	color: #61656E;
			}

			&:-ms-input-placeholder {
			   	color: #61656E;
			}
		}
		.select-style {
		    border: 1px solid $gray;
		    width: 100%;
		    overflow: hidden;
		    position: relative;
			margin-bottom: 34px;
			&.half {
				width: 48%;
				float: left;
				&:first-child {
					margin-right: 2%
				}
				&:last-child {
					margin-left: 2%
				}
			}
		    &:after {
		    	content: '';
		    	display: block;
		    	width: 12px;
		    	height: 9px;
		    	position: absolute;
		    	top: 20px;
		    	right: 16px;
		    	background: url("../images/icon-select.png") no-repeat;
		    }
		    select {
		    	margin-bottom: 0;
			    padding: 0 28px 0 12px;
			    border: none;
			    box-shadow: none;
			    background: transparent;
			    background-image: none;
			    -webkit-appearance: none;
			    &:focus {
			    	outline: 0;
			    }
			}
		}
		input[type=checkbox] {
			position: absolute;
			top: -9999px;
			left: -9999px;
			display: none;
		}
		input[type=checkbox] ~ label {
			@include secondary-font;
			font-size: 15px;
			-webkit-font-smoothing: subpixel-antialiased;
			margin-bottom: 34px;
			display: block;
		}
		input[type=checkbox] ~ label::before {
			content: ' ';
			display: inline-block;
			line-height: 14px;
			width: 14px;
			height: 14px;
			border: 1px solid #b3afa7;
			vertical-align: middle;
			margin-right: 10px;
			position: relative;
			top: -1px;
		}
		input[type=checkbox]:checked ~ label::before {
			background-color: #61656E;
		}
		.form-action {
			text-align: center;
		}
	}
	.columns.large-6.cart-summary {
		padding-left:0;
		.cart-summary-product {
			padding: 32px 0;
			border-bottom: 1px solid $gray;
			h2 {
				font-size: 18px;
				border: 0;
				padding: 0;
			}
			.color-size {
				font-size: 14px;
				color: $gold;
				text-transform: uppercase;
			}
			img.product-image {
				width: 100%;
				height: auto;
				float:left;
				margin-bottom: 16px;
			}
			.product-details {
				width: 100%;
				float: left;
			}
			.columns.title {
				width: 80%;
				padding-left: 32px;
			}
			.columns.subtotal {
				width: 20%;
				padding-left: 32px;
			}
		}
		.total-calculation, .cart-total {
			text-transform: uppercase;
			font-size: 18px;
			font-weight: bold;
			line-height: 60px;
			border-bottom: 1px solid $gray;
			span {
				font-weight: 400;
				color: $gold;
				display: inline-block;
				float: right;
			}
			div {
				padding: 0 28px;
				height: 60px;
				line-height: 60px;
				&:last-child {
					border-bottom: 0;
				}
			}
		}
		.cart-total {
			padding: 0 28px;
		}
	}
	.columns.large-6.order-review {
		padding-left: 0;
		border-right: 1px solid $gray;
	}
	.order-review-section {
		text-transform: uppercase;
		font-size: 14px;
		color: $gold;
		border-bottom: 1px solid $gray;
		padding: 34px 0;
		span {
			display: block;
			padding: 0 32px;
			line-height: 25px;
		}
		h3 {
			font-size: 18px;
			padding: 0 32px;
			font-weight: bold;
			color: #61656E;
			margin-bottom: 20px;
		}
		&:last-child {
			border-bottom: 0;
		}
	}
	.order-submit-holder, .order-review-loader {
		text-align: center;
		display: none;
	}
	.order-submit-holder {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.button.gold {
		&:before {
			content: '';
			display: none;
		}
	}
	.order-review-loader {
		padding-top: 32px;
	}
	.order-submit-holder {
		a.button {
			margin-top: 40px;
		}
	}
}
@include queryWidth(max, 1400px) {
	.checkout {
		.textured-holder {
			.inner {
				width: 100%;
				border: 0;
			}
		}
		.checkout-header {
			.wrapper {
				width: 100%;
			}
		}

		.columns.large-6.cart-summary {
			.cart-summary-product {
				.columns.subtotal, .columns.title {
					padding: 0 28px;
					width: 100%;
				}
				.columns.subtotal {
					padding-top: 12px;
				}
			}
		}
		.address-form-holder {
			.form-action {
				text-align: left;
			}
		}
	}
}
@include mobile {
	.checkout {
		.checkout-header.display-for-small-only {
			border-top: 1px solid $gray;
		}
		.address-form-holder, .order-review-holder {
			border-right: 0;
		}
		.inner.flex {
			display: block;
		}
	}
}