@mixin desktop {
    @media only screen and (min-width: 1025px) {
        @content;
    }
}
@mixin tablet {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}
@mixin mobile {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin queryWidth ($minOrMax, $val) {
    @media only screen and (#{$minOrMax}-width: $val) {
        @content;
    }
}

@mixin queryHeight ($minOrMax, $val) {
    @media only screen and (#{$minOrMax}-height: $val) {
        @content;
    }
}