$white: #ffffff;
$black: #231F20;
$gray: #bab9b7;
$grayText: #60646D;
$grayBackground: #42464f;
$background-dark-gray: #d9d9d9;
$background-light-gray: #f3f3f5;
$button-gray: #c4c4c4;
$black-background: #2a2a2a;
$yellow: #EBE415;
$gold: #8b8046;
$tan: #b3afa6;
$darkGrayText : #414850;
$error: #cc0000;
$blueLink: #0000A3;