.testimonials-textured-holder {
	.inner {
		border-right: 0;
		border-left: 0;
		position: relative;
		&:before {
			width: 4px;
			height: 100%;
			top: 0;
			right: -1px;
			background: $white;
			content: '';
			position: absolute;
			z-index: 2;
		}
		&:after {
			width: 1px;
			height: 100%;
			top: 0;
			right: 1px;
			background: $gray;
			content: '';
			position: absolute;
			z-index: 3;
		}
		.testimonials-header {
			.header-section {
				border-left: 1px solid $gray;
			}
			h2 {
				font-weight: 700;
			    font-size: 20px;
			    text-transform: uppercase;
			    color: #60646D;
			    padding: 68px 0 22px 38px;
			    width: 50%;
			    border-bottom: 0;
			}
		}
		.filter-trigger {
			width: 50%;
			text-align: right;
			padding-right: 14px;
			padding-top: 38px;
		}
		.button {
			margin-top:20px;
			text-transform: uppercase;
			background-color: $white;
			line-height: 40px;
			display: inline-block;
			padding: 0 42px;
			border-radius: 20px;
			color: #424850;
			font-size: 13px;
			text-align: center;
			border: 1px solid $grayText;
			@include transition(color 0.15s ease-out, background-color 0.15s ease-out);
			&:hover, &.open {
				text-decoration: none;
				color: $white;
				border: 0;
				background-color: $gold;
				border: 1px solid $gold;
				font-weight: 700;
			}
		}
		#testimonials-filter-holder {
			text-align: center;
			padding: 32px;
			border-bottom: 1px solid $gray;
			display: none;
			border-left: 1px solid $gray;
			&.open {
				display: block;

				border-top: 1px solid $gray;
			}
			.check-label-holder {
				display: inline-block;
			}
			label {
				color: #b3afa7;
				font-size: 16px;
				display: block;
				cursor: pointer;
				text-transform: uppercase;
				margin-right: 44px;
			}
			input[type=checkbox] {
				position: absolute;
				top: -9999px;
				left: -9999px;
				display: none;
			}
			input[type=checkbox] ~ label::before {
				content: ' ';
				display: inline-block;
				line-height: 16px;
				width: 16px;
				height: 16px;
				border: 1px solid #b3afa7;
				vertical-align: middle;
				margin-right: 18px;
				position: relative;
				top: -1px;
			}
			input[type=checkbox]:checked ~ label::before {
				background-color: #61656E;
			}
		}
		.filter-apply-holder {
			text-align: center;
			display: none;
			border-bottom: 1px solid $gray;
			border-left: 1px solid $gray;
			&.open {
				display: block;
			}
			a {
				border-color: $tan;
				color: #424850;
				margin-bottom: 20px;
			}
		}
		.testimonials-holder {
			background: url(../images/texture-bg.jpg) repeat;
			width: calc(100% + 1px);
			margin: 0 0 0 1px;
			position: relative;
			.testimonial {
				background: $white;
				width: calc( (100% + 2px) / 3 );
				padding: 88px 54px 54px;
				border: 1px solid $gray;
				margin: -1px 0 0 -1px;
				.testimonial-name {
					text-transform: uppercase;
					font-size: 20px;
					line-height: 24px;
					color: $grayText;
					border-bottom: 1px solid $gold;
					padding-bottom: 26px;
					font-weight: bold;
					span {
						display: block;
						color: $gold;
						font-size: 16px;
						font-weight: 400;
						margin-top: 16px;
					}
				}
				.testimonial-content {
					padding-top: 26px;
					font-size: 16px;
					color: $black;
					@include secondary-font;
				}
			}
		}
		.no-testimonials {
			text-align: center;
			width: 100%;
			padding: 84px;
			font-size: 16px;
			color: #61656E;
			text-transform: uppercase;
			width: 100%;
			a {
				text-decoration: underline;
			}
		}

	}
}
@include tablet {
	.testimonials-textured-holder {
		.inner {
			#testimonials-filter-holder {
				label {
					margin-right: 24px;
				}
			}
			.testimonials-holder {
				.testimonial {
					width: calc( (100% + 2px) / 2 );
				}
			}
		}
	}

}

@include queryWidth(max, 920px) {
	.testimonials-textured-holder {
		.inner {
			#testimonials-filter-holder {
				text-align: left;
				.check-label-holder {
					display: block;
					margin: 6px 0;
				}
				label {
					margin-right: 0;
				}
			}
		}
	}
}
@include mobile {
	.testimonials-textured-holder {
		.inner {
			&:before, &:after {
				display: none;
			}
			.testimonials-header {
				h2 {
					width: 60%;
				}
				.filter-trigger {
					width: 40%;
				}
			}
			.testimonials-holder {
				.testimonial {
					padding: 40px;
					width: calc(100% + 1px);
					.testimonial-name {
						span {
							margin-top: 8px;
						}
					}
				}
			}
		}
	}
}
@include queryWidth(max, 540px) {
	.testimonials-textured-holder {
		.inner {
			.testimonials-header {
				h2 {
					width: 100%;
				}
				.filter-trigger {
					width: 100%;
					text-align: left;
					padding: 0 0 22px 38px;
					a {
						margin-top: 10px;
					}
				}
			}
		}
	}
}