.product-description-section-header {
	.inner {

		border-bottom: 1px solid $gray;
	}
	h1 {
		font-size: 20px;
		text-transform: uppercase;
		color: #61656E;
		border-bottom: 1px solid $gold;
		padding: 46px 0px 20px;
		margin-left: 32px;
		display: inline-block;
		span {
			display: inline-block;
			margin-left: 22px;
			font-size: 14px;
			color: $gold;
		}
	}
	.description {
		font-size: 18px;
		color: #61656E;
		@include secondary-font;
		padding: 0 32px 42px 32px;
		p {
			margin-bottom: 0;
			margin-top: 20px;
		}
	}
}
.product-description-section {
	.wrapper {
		width: 1216px;
	}
	background: #efeff1;
	width: 100%;
	float: left;
	&.textured-holder.options-comparison {
		background: url(../images/texture-bg.jpg) repeat;
   		display: flex;
		.inner {
			  background: #ffffff;
			  width: 1216px;
			  margin: 0 auto;
			  border-left: 1px solid #bab9b7;
			  border-right: 1px solid #bab9b7;
		}
		.columns {
			min-height:100%;
    		height:100%
		}
		.columns:first-child {
			border-right: 1px solid $gray;
		}
		.product-specs {
			border-top: 0;
			margin-top: 64px;
			ul {
				padding: 0;
			}
			a.specs-download {
				margin: 32px 0;
			}
		}
		.description-column {
			padding: 68px 60px 30px;
			.description {
				font-size: 18px;
				ul {
					padding-left: 40px;
				}
			}
		}
	}
	.main-image-holder {
		width: 600px;
		height: 450px;
		text-align: center;
		overflow: hidden;
		position: relative;
		margin: 48px auto 40px;
		div {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			text-align: center;
		}
		img {
			visibility: hidden;
			opacity: 0;
			@include transition(opacity 0.2s linear,visibility 0.2s linear);
			&.visible {
				visibility: visible;
				opacity: 1;
  				max-width: 100%;
			}
		}
	}
	.image-navigation {
		text-align: center;
		margin-bottom: 78px;
		img {
			cursor: pointer;
			border: 2px solid $white;
			border-radius: 50%;
			width: 32px;
			height: 32px;
			display: inline-block;
			margin: 0 10px;
		}
	}
	.product-options {
		padding: 0 32px 28px;
		text-align: center;
		h3 {
			border-bottom: 0;
			margin-bottom: 0;
		}
		.product-option {
			padding: 0 10px;
			.option-title {
				font-size: 13px;
				@include secondary-font;
				text-align: left;
				margin-top: 12px;
			}
			&.columns:first-child {
				border: 0;
			}
		}
	}
	.product-price {
		text-transform: uppercase;
		font-size: 15px;
		font-weight: 700;
		color: $gold;
		margin-bottom: 12px;
	}
	h1, h3 {
		text-transform: capitalize;
		color: $grayText;
		font-size: 25px;
		border-bottom: 1px solid $gold;
		padding-bottom: 18px;
		margin-bottom: 20px;
	}
	h3 {
		font-size: 20px;
	}
	.description-column {
		padding: 92px 90px;
		.description {
			@include secondary-font;
			font-size: 16px;
			color: $grayText;
			margin-bottom: 44px;
			p {
				margin: 20px 0;
			}
		}
	}
}
.product-specs {
	border-top: 1px solid $gray;
	.inner {
		border-bottom: 1px solid $gray;
	}
	&.options-comparison {
		border-top: 0;
	}
	ul {
		list-style: none;
		margin:0;
		padding: 24px 32px;
		li {
			color: $gold;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 44px;
			border-bottom: 1px dotted $gray;
			span:last-child {
				color: $grayBackground;
				float: right;
			}
		}
	}
	a.specs-download {
		margin: 0 0 20px 32px;
		border: 1px dotted $gray;
		font-size: 15px;
		text-transform: uppercase;
		color: $grayBackground;
		height: 46px;
		line-height: 46px;
		display: inline-block;
        padding: 0 20px;
		span {
			content: ' ';
            background: url(../images/sprite.png) no-repeat -68px -140px;
            width: 12px;
            height: 18px;
            display: inline-block;
            margin-right: 2px;
            position: relative;
            vertical-align: middle;
		}
	}
	.attributes {
		border-top: 1px solid $gray;
		padding: 32px;
		@include secondary-font;
		font-size: 16px;
		color: $grayBackground;
		strong {
			color: $gold;
			text-transform: uppercase;
			font-size: 14px;
			@include primary-font;

		}
	}
}
.product-inspirations-holder {
	position: relative;
	width: 100%;
	.product-inspiration-footer, .product-inspiration-images {
		float: left;
		width: 100%;
	}
	.product-inspiration-images {
		//height: 630px;
		img {
			width: 100%;
			display: block;
			object-fit: cover;
		}
	}

	.slick-track
	{
		display: flex !important;
	}

	.slick-slide
	{
		height: inherit !important;
		max-height: 630px;
	}

	.product-inspiration-footer {
		height: 82px;
		line-height: 82px;
		background: $white;
		.product-inspiration-navigation {
			width: 9.704%;
			text-align: center;
			border-left: 1px solid $gray;
			height: 82px;
			a {
				height: 18px;
				width: 10px;
				vertical-align: middle;
				display: inline-block;
				margin: 0 14px;
			}
			.product-inspiration-navigation-left {
				background-position: -80px -114px;
			}
			.product-inspiration-navigation-right {
				background-position: -90px -114px;
			}
		}
		.product-inspiration-titles {
			width: 75.987%;
			color: #424850;
			font-size: 14px;
			border-left: 1px solid $gray;
			height: 82px;
			position: relative;
			.product-inspiration-title {
				opacity: 0;
				position: absolute;
				width: 100%;
				height: 100%;
				padding-left: 34px;
				top: 0;
				left: 0;
				&:first-child {
					opacity: 1;
				}
			}

			span {
				color: $gold;
				&.reference-number {
					color: #424850;
					font-size: 11px;
					float: right;
					padding-right: 16px;
				}
			}
			&.single {
				width: 85.691%;
			}
		}
		.product-inspiration-more {
			width: 14.309%;
			font-size: 12px;
			color: #8E9295;
			height: 82px;
			border-left: 1px solid $gray;
			border-right: 1px solid $gray;
			text-align: center;
			a {
				text-decoration: underline;
			}
		}
	}
	.inspiration-contact {
		text-align: center;
		padding: 0 200px;
		h3 {
			font-size: 20px;
			color: #61656E;
			text-transform: uppercase;
			padding-top: 38px;
			margin-bottom: 26px;
		}
		.columns {
			text-align: center;
			margin-bottom: 34px;
			a.button.gold {
				&:before {
					content: '';
					display: none;
				}
			}
		}
	}

}
.mantel-product {
	.header-images-section {
		padding: 0 24px;
		border-bottom: 1px solid $gray;
	}
	h1 {
		display: inline-block;
	    font-weight: 700;
	    font-size: 20px;
	    text-transform: uppercase;
	    color: #60646D;
	    border-bottom: 2px solid $gold;
	    padding: 68px 0 22px 0;
	    position: relative;
	    margin-bottom: 68px;
	}
	.mantel-image-holder {
		min-height: 180px;
		.image-label {
			text-transform: uppercase;
	    	color: #60646D;
	    	font-size: 14px;
		}
		img {
			max-width: 95%;
			display: inline-block;
			margin: 30px 5% 30px 0;
		}
	}
	.mantel-product-lower {
		display: flex;

	}
	.mantel-finish-options {
		border-right: 1px solid $gray;
		padding: 30px;
		flex: 1;
		h2 {
			text-transform: uppercase;
	    	color: #60646D;
	    	text-align: center;
	    	padding: 10px 0 18px 0;
	    	border: 0;
		}
		.finish-option {
			padding: 12px 8px;
			img {
				max-width: 100%;
			}
			.image-label {
	    		color: #60646D;
	    		font-size: 14px;
	    		@include secondary-font;
				-webkit-font-smoothing: subpixel-antialiased;
			}
		}
	}
	.mantel-option-purchase {
		flex: 1;
		padding: 30px;
		h2 {
			display: inline-block;
		    font-weight: 700;
		    font-size: 20px;
		    text-transform: uppercase;
		    color: #60646D;
		    border-bottom: 1px solid $gold;
	    	padding: 10px 0 18px 0;
		    position: relative;
		    margin-bottom: 18px;
		}
		.mantel-product-description {
			@include secondary-font;
			font-size: 16px;
			color: $grayText;
			margin-bottom: 44px;
		    -webkit-font-smoothing: subpixel-antialiased;
		}
		.mantel-product-dimensions {
			font-size: 14px;
			border-bottom: 1px dotted $gray;
			color: $grayText;
 			border-top: 1px dotted $gray;
 			padding: 12px 0;
		    margin-bottom: 30px;
 			span:first-child {
 				color: $gold;
 				text-transform: uppercase;
 			}
 			span:last-child {
 				float: right;
 			}
		}

		#custom-cut-instructions, #custom-cut-length {
			display: none;
		}
		.form-field-holder {
			label {
				color: #60646D;
				text-transform: uppercase;
				width: 100%;
				font-size: 14px;
				display: block;
			}
			.select-style {
			    border: 1px solid $gray;
			    width: 100%;
			    overflow: hidden;
			    position: relative;
			    height: 48px;
			   	margin: 12px 0 24px 0;
			    &:after {
			    	content: '';
			    	display: block;
			    	width: 12px;
			    	height: 9px;
			    	position: absolute;
			    	top: 20px;
			    	right: 16px;
			    	background: url("../images/icon-select.png") no-repeat;
			    }
			    select {
				    padding: 0 28px 0 12px;
				    border: none;
					color: $grayText;
				    box-shadow: none;
				    background: transparent;
				    background-image: none;
				    -webkit-appearance: none;
				    line-height: 48px;
				    width: 100%;
	    			@include secondary-font;
				    -webkit-font-smoothing: subpixel-antialiased;
				    &:focus {
				    	outline: 0;
				    }
				}
			}
			textarea {
				border: 1px solid $gray;
			}
			input[type=checkbox] {
				position: absolute;
				top: -9999px;
				left: -9999px;
				display: none;
			}
			input[type=checkbox] ~ label::before {
				content: ' ';
				display: inline-block;
				line-height: 14px;
				width: 14px;
				height: 14px;
				border: 1px solid #b3afa7;
				vertical-align: middle;
				margin-right: 6px;
				position: relative;
				top: -1px;
			}
			input[type=checkbox]:checked ~ label::before {
				background: url(../images/check.png) no-repeat center center;
			}
			input[type=checkbox] ~ label {
				font-size: 12px;
				line-height: 18px;
				text-transform: none;
				a {
					text-decoration: underline;
				}
			}
		}
		.mantel-product-price {
			text-transform: uppercase;
			font-size: 28px;
			font-weight: 400;
			color: $gold;
			margin-bottom: 12px;
			margin: 18px 0 24px;
		}
		input[type="submit"] {
			display: none;
		}
		.button.gold {
			min-width: 340px;
		}
		textarea, input[type="text"] {
			width: 100%;
			display: block;
			height: 48px;
			padding: 12px;
			border: 1px solid $gray;
			@include secondary-font;
			-webkit-font-smoothing: subpixel-antialiased;
			border-radius: 0;
			background: $white;
			display: block;
			margin: 12px 0 24px 0;
		}
		textarea {
			height: 170px;
		}
		textarea, input[type="text"] {
			&::-webkit-input-placeholder {
				color: $grayBackground;
			}

			&:-moz-placeholder { /* Firefox 18- */
			   	color: $grayBackground;
			}

			&::-moz-placeholder {  /* Firefox 19+ */
			   	color: $grayBackground;
			}

			&:-ms-input-placeholder {
			   	color: $grayBackground;
			}
		}
	}
}
@include queryWidth(max, 1400px) {
	.product-description-section {
		&.textured-holder.options-comparison {
			.inner {
				width: 90%;
			}
			.description-column {
				.description {
				  font-size: 16px;
				}
			}
			.main-image-holder {
				width: auto;
			}
			.button.gold {
				padding: 0 30px;
			}
		}
		.product-options {
			.product-option {
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
		.product-specs {
			ul {
				padding: 0 16px;
				li {
					font-size: 12px;
					line-height: 18px;
					padding: 10px 0;
					span, span:last-child {
						display: inline-block;
						width: 100%;
						&:last-child {
							float: none;
						}
					}
				}
			}
			.spec-sheet-holder {
				padding: 0 16px;
			}
			a.specs-download {
				margin: 18px 0;
				font-size: 13px;
			}
		}
	}
	.mantel-product {
		.textured-holder {
			.inner {
				width: 100%;
				border: 0;
			}
		}
		.mantel-option-purchase {
			.button.gold {
				max-width: 100%;
				min-width: 0;
			}
		}
	}
}
@include queryWidth(max, 1216px) {
	.product-description-section {
		.wrapper {
			width: 100%;
		}
	}
}
@include tablet {
	.product-description-section {
		&.textured-holder.options-comparison {
			.columns {
				min-height: 0;
				height: auto;
			}
			.columns:first-child {
				border-right: 0;
			}
			.product-options {
				.product-option {
					img {

					}
					.option-title {
						text-align: center;
					}
				}
			}
			.description {
				border-top: 0;
				padding: 0 24px;
			}
			.product-specs {
				margin-top: 10px;
			}
		}
		.description-column {
			background: $white;
			padding: 0;
			h1 {
				line-height: 114px;
				padding: 0 24px 0 38px;
				border-top: 1px solid $gray;
				border-bottom: 0;
				font-size: 20px;
				margin: 0;
				span {
					padding-bottom: 4px;
					border-bottom: 1px solid $gold;
					&.product-price {
						border-bottom: 0;
						color: $gold;
						font-size: 15px;
						font-weight: 700;
						display: inline;
						float: right;
					}
				}
			}
			.button {
				display: none;
			}
		}
		.product-actions {
			text-align: center;
			border-top: 1px solid $gray;
			padding: 32px 24px;
			.button {
				width: 100%;
				display: inline-block;
			}
		}
		.description {
			border-top: 1px solid $gray;
			padding: 32px 24px 0 24px;
		}
	}
	.product-specs {
		.spec-sheet-holder {
			padding: 0 32px;
		}
		a.specs-download {
			width: 100%;
			text-align: center;
			margin: 0 0 24px 0;
		}
	}
	.product-inspirations-holder {
		.product-inspiration-footer {
			height: 164px;
			.product-inspiration-titles, .product-inspiration-titles.single {
				width: 100%;
				border-bottom: 1px solid $gray;
				border-right: 1px solid $gray;
			}
			.product-inspiration-navigation, .product-inspiration-more {
				width: 50%;
				&.single {
					width: 100%;
				}
			}
			.product-inspiration-navigation {
				a {
					margin: 0 30px;
				}
			}
		}
		.inspiration-contact {
			padding: 0 100px;
		}
	}
}
@include mobile {
	.product-description-section-header {
		h1 {
			font-size: 14px;
			display: block;
			span {
				display: block;
				margin-left: 0;
				font-size: 12px;
			}
		}
		.description {
			font-size: 14px;
		}
	}
	.product-description-section {
		&.textured-holder.options-comparison {
			.main-image-holder {
				max-width: 320px;
				height: 320px;
			}
			.product-options {
				max-width: 400px;
				margin: 0 auto;
			}
			.description-column {
				padding: 24px;
				.description {
					font-size: 14px;
					padding: 0;
				}
				.button {
					font-size: 12px;
					padding: 0 20px;
				}
			}

				.product-specs {
					a.specs-download {
						font-size: 11px;
					}
				}

		}
		.main-image-holder{
			width: 320px;
			height: 240px;
			img {
				width: 100%;

			}
		}
		.image-navigation {
			margin-bottom: 20px;
			img {
				width: 24px;
				height: 24px;
				margin: 0 5px;
			}
		}
		.product-actions {
			padding: 24px 16px;
		}
		.description-column {
			h1 {
				padding: 28px 16px;
				line-height: normal;
				font-size: 14px;
				span {
					&.product-price {
						float: left;
						display: inline-block;
						width: 100%;
						font-size: 12px;
						margin-bottom: 12px;
						&.display-for-medium-only {
							display: none;
						}
					}
				}
			}
			.button {
				//display: none;
			}
		}
	}
	.product-specs {
		ul {
			padding: 0 16px;
			li {
				font-size: 12px;
				line-height: 18px;
				padding: 10px 0;
				span, span:last-child {
					display: inline-block;
					width: 100%;
					&:last-child {
						float: none;
					}
				}
			}
		}
		.spec-sheet-holder {
			padding: 0 16px;
		}
		a.specs-download {
			margin: 18px 0;
			font-size: 13px;
		}
	}
	.product-inspirations-holder {
		.inspiration-contact {
			padding: 0 20px;
			h3 {
				font-size: 14px;
			}
			.button.gold {
				font-size: 12px;
				padding: 0 20px;
			}
		}
		h3 {
			font-size: 14px;
		}
		.product-inspiration-footer {
			height: 124px;
			.product-inspiration-titles {
				 font-size: 13px;
				 height: 62px;
				 line-height: normal;
				.product-inspiration-title {
					display: table;
					height: 62px;
					padding: 0 16px;
					div {
						display: table-cell;
						vertical-align: middle;
					}
				}
			}
			.product-inspiration-navigation {
				height: 62px;
				line-height: 62px;
			}
			.product-inspiration-more {
				height: 62px;
				line-height: 62px;
			}
		}
	}
	.mantel-product {
		.mantel-product-lower {
			display: block;
		}
		.mantel-option-purchase .button.gold {
			min-width: 280px;
		}
	}
}


