
#desktop-navigation, #header.reduced-height {
    height: 114px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: $white;
    @include menu-font;
    visibility: hidden;
    opacity: 0;
    @include transition(opacity 0.2s linear,visibility 0.2s linear);
    @include box-shadow(0 0 6px rgba(0, 0, 0, 0.125),0 6px 12px rgba(0, 0, 0, 0.25));

    // .iris-inline & {
    //     top: 55px;
    // }
    .telephone-row {
        margin-right: 42px;
        padding-top: 8px;
        color: #424850;
        font-size: 13px;
        text-transform: uppercase;
        @include primary-font;
        -webkit-font-smoothing: antialiased;
        &:before {
            content: " ";
            background: url(../images/sprite.png) no-repeat -1px -1px;
            width: 11px;
            height: 16px;
            display: inline-block;
            margin-right: 6px;
            position: relative;
            top: 3px;
        }
        a {
            font-weight: bold;
        }
    }
    &.open, &.scrolled {
        visibility: visible;
        opacity: 1;
    }
    .desktop-header-navigation {
        text-align: right;
        padding-top: 10px;
    }
    a.logo {
        display: inline-block;
        background-position: -75px -68px;
        width: 186px;
        height: 38px;
        margin: 36px 50px;
        float: left;
    }
    ul {
        display: inline-block;
        list-style: none;
        font-size: 14px;
        color: $black;
        margin: 0;
        li {
            height: 60px;
            display: inline-block;
            margin: 0 20px;
            position: relative;
            &.dropdown {
                a:after {
                    content: ' ';
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 4px 0 4px;
                    border-color: $gold transparent transparent transparent;
                    margin-left: 6px;
                    vertical-align: middle;
                }
                ul {
                    text-align: left;
                    border: 1px solid $gray;
                    width: 230px;
                    padding: 10px 24px;
                    color: #A2A2A4;
                    font-size: 15px;
                    visibility: hidden;
                    opacity: 0;
                    @include transition(opacity 0.2s linear,visibility 0.2s linear);
                    -webkit-font-smoothing: antialiased;
                    position: absolute;
                    top: 40px;
                    left: 0;
                    background: $white;
                    li {
                        height: auto;
                        margin: 7px 0;
                        display: block;
                        a {
                            @include transition(color 0.1s linear);
                            &:after {
                                display: none;
                            }
                            &:hover {
                                color: $black;
                            }
                        }
                        a:after {
                            display: none;
                        }
                    }
                }
            }
            &.dropdown.showrooms {
                ul {
                    width: 200px;
                }
            }
            &.dropdown:hover {
                ul {
                    visibility: visible;
                    opacity: 1;
                    display: block;
                }
            }
        }
    }
}
.iris-inline #desktop-navigation {
    top: 55px;
}
#mobile-navigation, #desktop-search-form {
    width: 700px;
    max-width: 100%;
    height: calc(100% - 114px);
    position: fixed;
    top: 114px;
    right: 0;
    z-index: 100;
    background: $grayBackground;
    overflow: scroll;
    @include menu-font;
    right: -700px;
    @include transition(right 0.2s ease);
    @include box-shadow(0 0 6px rgba(0, 0, 0, 0.125),0 6px 12px rgba(0, 0, 0, 0.25));
    &.open {
        right: 0;
    }
    form {
        border-bottom: 1px solid #62666f;
        padding: 32px;
        input[type="text"] {
            background: #62666f;
            color: $white;
            line-height: 40px;
            padding: 0 10px;
            width: calc(100% - 44px);
            float: left;
            display: block;
            border: 0;
        }
        button[type="submit"] {
            background: #62666f;
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin-left: 4px;
            float: left;
            display: block;
            border: 0;
            padding: 0;
            span {
                background-position: -38px -140px;
                height: 22px;
                width: 22px;
                display: block;
                position:relative;
                left: 9px;
           }
        }
    }
    .links {
        display: flex;
        border-bottom: 1px solid #62666f;
        .columns {
            flex: 1;
            padding: 10px 0 50px 40px;
        }
        .columns:first-child {
            border-right: 1px solid #62666f;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            color: $white;
            li {
                margin-top: 26px;
                font-size: 20px;
                font-weight: 700;
                -webkit-font-smoothing: antialiased;
                ul {
                    margin-top: 10px;
                    li {
                        margin-top: 0;
                        font-size: 16px;
                        color: #A2A2A4;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .social-links {
        padding: 40px 0 0 40px;
        a {
            width: 25px;
            height: 25px;
            display: inline-block;
            margin-right: 8px;
            &.facebook {
                background-position: -40px -180px;
            }
            &.houzz {
                background-position: -65px -180px;
            }
            &.pinterest {
                background-position: -89px -180px;
            }
            &.instagram {
                background-position: -114px -180px;
            }
        }
    }
}

#desktop-search-form {
    height: 104px;
}
#header.reduced-height {
    .visit-showroom {
       @include primary-font;
    }
}
.top-navigation-row, #mobile-header-copy {
    display: none;
    .logo {
        background-position: -75px -27px;
        width: 186px;
        height: 38px;
        margin: 36px 50px;
        float: left;
    }
    .column:last-child {
        text-align: right;
        padding-right: 40px;
        padding-top:20px;
        font-size: 13px;
        text-transform: uppercase;
        div, a {
            display: inline-block;
        }
        a {
            font-weight: 700;
        }
        .visit-showroom {
            @include primary-font;
            &:before {
                content: ' ';
                background: url(../images/sprite.png) no-repeat -1px -1px;
                width: 11px;
                height: 16px;
                display: inline-block;
                margin-right: 4px;
                position: relative;
                top: 4px;
            }
        }
    }
}

#header.full-height  {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 588px;
    background-position: center center;
    color: $white;
    position: relative;
    padding-top: 74px;
    .opacity-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.47);
    }
    #home-header-backgrounds {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 805px;

        div.home-header-background {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 805px;
            opacity: 0;
            &:first-child {
                opacity: 1;
            }
        }
    }
    h1, .product-category-hero-image-header {
    	font-weight: 700;
    	font-size: 44px;
    	margin: 84px 0 82px 136px;
    	text-transform: uppercase;
    	line-height: 60px;
        position: relative;
        max-width: 640px;
    }
    &.subheader {
        h1, .product-category-hero-image-header {
            margin-bottom: 10px;
        }
    }
    .hero-image-header-holder {
        position:relative;
        margin:0 0 62px 136px;
        height: 224px;
        h1.home-header-h1 {
            display: none;
            margin: 0 136px 20px 0;
            line-height: normal;
            max-width: none;
            font-size: 18px;
            font-weight: 400;
            text-transform: uppercase;
        }
        .hero-image-header {
            margin: 0;
            position: absolute;
            left: 0;
            right: 0;
            opacity: 0;
            font-weight: 700;
            font-size: 44px;
            text-transform: none;
            line-height: 60px;
            padding-right: 40px;
            max-width: 800px;
            opacity: 0;
            text-shadow: none;
            &.hero-image-header-0 {
                opacity: 1;
            }
        }
    }
    h2 {
        font-size: 18px;
        font-weight: 400;
        margin: 0 0 82px 136px;
        margin: 0 136px 20px 136px;
        line-height: normal;
        max-width: none;
        font-size: 18px;
        font-weight: 400;
        position: relative;
    }
    &.home-header h1 {
        margin-top: 176px;
        margin-bottom: 68px;
    }
    .shop-button-holder {
        margin-left: 136px;
        height: 50px;
        position: relative;
        .shop-button {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            visibility: hidden;
            &:first-child {
                opacity: 1;
                visibility: visible;
            }
            a {
                font-size: 15px;
                line-height: 50px;
                border: 1px solid $white;
                border-radius: 25px;
                font-weight: 700;
                display: inline-block;
                padding: 0 25px;
                background: $white;
                color: $black;
                &:hover {
                    background: $white;
                }
                &:after {
                    content: ' ';
                    background: url(../images/sprite.png) no-repeat -268px -300px;
                    width: 49px;
                    height: 16px;
                    display: inline-block;
                    top: 3px;
                    position: relative;
                    margin-left: 18px;
                }
                @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
                 only screen and (-o-min-device-pixel-ratio: 3/2),
                 only screen and (min--moz-device-pixel-ratio: 1.5),
                 only screen and (min-device-pixel-ratio: 1.5) {
                    &:after {
                        background-image: url(../images/sprite@2x.png) no-repeat;
                        background-size: 320px 320px;
                    }
                }
            }
        }
    }

    .down-arrow {
        display: none;
        height: 55px;
        width: 55px;
        background-position: -106px -112px;
        margin-left: 136px;
        margin-top: 14px;
        float: left;
        cursor: pointer;
        &:hover {
            background-position: -161px -111px;
        }
    }
    .bottom-navigation-row {
        position: relative;
    	margin: 48px 0 28px 136px;
    	ul {
            margin: 0;
            padding: 0;
    		list-style: none;
            font-size: 13px;
    		li {
    			display: inline-block;
    			margin-right: 8px;
                margin-bottom: 8px;
    			a {
    				line-height: 28px;
    				padding: 0 12px;
    				display: inline-block;
                    border-radius: 16px;
                    border: 1px solid $white;
                    color: $white;
                    @include transition(background-color 0.2s ease, color 0.2s ease);
    				&.active, &:hover {
                        border: 1px solid $gold;
                        background: $gold;
                        color: $white;
                        text-decoration: none;
    				}
    			}
    		}
    	}
    }
    .slide-navigation {
        position: relative;
    	margin-left: 150px;
    	.left, .right {
    		display: inline-block;
    		width: 17px;
    		height: 30px;
    		padding: 7px 4px;
            margin-left: 10px;
    		span {
    			height: 16px;
    			width: 9px;
    			display: inline-block;
    		}
    	}
    	.left {
    		span {
    			background-position: -30px -1px;
    		}
    	}
    	.right {
    		span {
    			background-position: -40px -1px;
    		}
    	}
    }
}
#header.full-height.home-header {
    height: 805px;
    .hero-image-header-holder {
        margin-top: 200px;
    }
}
.cart-link {
    position: relative;
    top: 4px;
    padding: 5px 6px;
    margin-left: 64px;
    span {
        background-position: -14px 0;
        width: 17px;
        height: 20px;
        display: inline-block;
    }
    & + span {
          content: ' ';
          width: 2px;
          height: 34px;
          background: #ffffff;
          display: inline-block;
          margin: 0 28px 0 28px;
          position: relative;
          top: 12px;
          opacity: 0.3;
          margin-top: 5px;
    }
}
.navigation-toggle, #search-toggle {
    position: relative;
    top: 4px;
    padding: 5px 2px;
    cursor: pointer;
    span {
        background-position: -38px -117px;
        width: 26px;
        height: 21px;
        display: inline-block;
    }
}
.desktop-header-navigation, #header.reduced-height {
    .cart-link {
        top: 6px;
        margin-left: 40px;
        span {
              background-position: -38px -74px;
        }
        & + span {
            background: $gray;

        }
    }
    #search-toggle {
        display: inline-block;
        margin-right: 42px;
        span {
            background-position: -38px -140px;
            height: 22px;
            width: 26px;
            top: 6px;
        }
        &.open {
            span {
                background-position: -80px -150px;
            }
        }
    }
}

#header.reduced-height {
    z-index: 1;
    visibility: visible;
    opacity: 1;
    position: relative;
    float: left;
    background: $white;
    color: $black;
    border-bottom: 1px solid $gray;
    box-shadow: none;
    .cart-link {
        top: 6px;
        margin-left: 40px;
        span {
              background-position: -38px -74px;
        }
        & + span {
            background: $gray;

        }
    }
    .column:last-child {
        text-align: right;
        //padding-right: 40px;
        padding-top: 30px;
        font-size: 13px;
        text-transform: uppercase;
        div, a {
            display: inline-block;
        }
        a {
            color: $gold;
            font-weight: 700;
            -webkit-font-smoothing: antialiased;
        }
        .visit-showroom {
            @include primary-font;
            -webkit-font-smoothing: antialiased;
            font-weight: normal;
            color: #424850;
            &:before {
                content: ' ';
                background: url(../images/sprite.png) no-repeat -1px -1px;
                width: 11px;
                height: 16px;
                display: inline-block;
                margin-right: 4px;
                position: relative;
                top: 4px;
            }
            a {
                color: #424850;
                font-weight: bold;
            }
        }
    }
    .navigation-toggle {
        display: inline-block;
        margin-right: 42px;
        span {
            background-position: -38px -94px;
        }
    }

}

.breadcrumbs {
    height: 58px;
    border-bottom: 1px solid $gray;
    background: $white;
    float: left;
    width: 100%;
    line-height: 58px;
    color: #8E9295;
    font-size: 12px;
    padding-left: 50px;
    text-transform: uppercase;
    a + a:before, a+span:before {
        content: '>';
        display: inline-block;
        margin: 0 10px;
        color: $gold;
        position: relative;
        top: 1px;
    }
}

@include queryWidth(max, 15000px) {
    #desktop-navigation {
        ul li {
            margin: 0 14px;
        }
    }
}

@include queryWidth(max, 1450px) {
    #desktop-navigation {
        ul li {
            margin: 0 10px;
        }
    }
}

@include queryWidth(max, 1330px) {
    #header.full-height  {
        h1, h2, .hero-image-header-holder, .shop-button-holder, .bottom-navigation-row, .slide-navigation, .product-category-hero-image-header {
            margin-left: 90px;
        }
        .bottom-navigation-row {
            margin-right: 40px;
            margin-bottom: 10px;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                font-size: 12px;
                li {
                    display: inline-block;
                    margin-right: 10px;
                    a {
                        line-height: 28px;
                        padding: 0 8px;
                        text-transform: uppercase;
                        &.active {
                            border: 2px solid $gold;
                            border-radius: 16px;
                        }
                    }
                }
            }
        }
    }
    #desktop-navigation {
        ul li {
            margin: 0 10px;
            font-size: 14px;
            ul li {
                font-size: 15px;
            }
        }
        .cart-link {
            margin-left: 15px;
            & + span {
                  margin: 0 16px 0 12px;
            }
        }
        #search-toggle {
            margin-right: 24px;
        }
    }
}

@include queryWidth(max, 1250px) {
    #desktop-navigation {
        ul li {
            font-size: 12px;
            margin: 0 4px;
            ul li {
                font-size: 14px;
            }
        }
    }
}

@include queryWidth(max, 1180px) {
    #desktop-navigation {
        //ul li {
        //    font-size: 12px;
        //    margin: 0 4px;
        //    ul li {
        //        font-size: 14px;
        //    }
        //}
    }
    #desktop-navigation a.logo, #header.reduced-height a.logo {
        // width: 155px;
        // height: 32px;
        // background-size: 260px 260px;
        // background-position: -61px -54px;
        // margin-top: 40px;
        // background-size: 320px 320px;
    }
}

@include queryWidth(max, 1024px) {
    #desktop-navigation {
        ul li {
            font-size: 14px;
            margin: 0 10px;
            ul li {
                font-size: 15px;
            }
        }
    }
    #desktop-navigation a.logo, #header.reduced-height a.logo {
        background-position: -75px -68px;
        width: 186px;
        height: 38px;
        margin: 36px 50px;
    }
}
@media only screen and (max-width: 960px) {
    .top-navigation-row, #mobile-header-copy {
        display: block;
    }
}
@media only screen and (max-width: 960px) and (min-width: 870px) {
    .cart-link {
        margin-left: 30px;
        & + span {
            margin: 5px 12px 0 12px;
        }
    }
    .top-navigation-row, #mobile-header-copy {
        .column:last-child {
        }
    }
}

@include queryWidth(max, 869px) {
    .top-navigation-row, #mobile-header-copy, #header {
        .column:last-child {
            .cart-link {
                margin-left: 20px;
            }
        }
    }
}

@include queryWidth(max, 840px) {
    .top-navigation-row, #mobile-header-copy, #header {
        .column:last-child {
            div.visit-showroom {
                display: block;
                float: left;
                position: relative;
                width: 100%;
            }
            .cart-link {
                margin-left: 20px;
            }
        }
    }
}

@include tablet {
    #desktop-navigation {
        display: none;
    }
     .top-navigation-row, #mobile-header-copy {
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        .logo {
            background-position: -75px -68px;
        }
        height: 114px;
        background: $white;
        .column:last-child {
            div.visit-showroom {
                //display: none;
                color: #424850
            }
        }
    }
    #header.full-height  {

        .down-arrow {
            margin-left: 90px;
        }
    }
    .cart-link {
        span {
            background-position: -38px -75px;
            height: 19px;
        }
        & + span {
            background: $gray;
        }
    }
    .navigation-toggle {
        span {
              background-position: -38px -94px;
        }
    }
    #mobile-header-copy {
        position: fixed;
        top: 0;
        z-index: 100;
        height: 114px;
        width: 100%;
        display: none;
        &.open {
            display: block;
        }
        .navigation-toggle {
            span {
                background-position: -80px -150px;
            }
        }
    }

    #header.full-height {
        .shop-button-holder {
        }
        .bottom-navigation-row {
            ul {
                li {
                    font-size: 12px;
                }
            }
        }
        .hero-image-header-holder {
            margin-left: 88px;
            margin-bottom: 66px;
        }
    }
}

@include queryWidth(max, 880px) {
    #header.full-height {

        .bottom-navigation-row {
            ul {
                li {
                    margin-right: 4px;
                    a {
                        padding: 0 8px;
                        &.active {
                            padding: 0 6px;
                        }
                    }
                }
            }
        }
    }
}

@include mobile {
    .top-navigation-row, #mobile-header-copy {
        min-height: 80px;
        width: 100%;
        .logo {
            margin: 31px 16px;
            background: transparent;
            height: auto;
            width: auto;
            img {
                height: 24px;
            }
        }
        .column:last-child {
            padding-top: 10px;
            padding-right: 18px;
        }
    }
    .top-navigation-row {
        height: 86px;
        top: -80px;
        position: absolute;
    }
    .cart-link + span {
          margin: 0 14px;
    }
    #header.full-height, #header.full-height.home-header {
        height: 398px;
        position: relative;
        margin-top: 80px;
        padding-top: 0;
        h1, .product-category-hero-image-header {
            margin: 66px 30px 32px 34px;
            font-size: 24px;
            line-height: 35px;
            max-width: 246px;
        }
        .shop-button-holder {
            margin-left: 0;
            height: auto;
            min-height: 50px;
            padding-right: 28px;
            padding-left: 28px;
            margin-top: 52px;
        }
        #home-header-backgrounds {
            height: 400px;
            div.home-header-background {
                height: 400px;
            }
        }
        .hero-image-header-holder {
            padding-top: 48px;
           margin: 66px 30px 12px 34px;
           h1.home-header-h1 {
                font-size: 16px;
                margin-right: 0;

           }
        }
        .hero-image-header {

            font-size: 24px;
            line-height: 35px;
            max-width: 346px;

        }
        &.subheader {
            padding-top: 80px;
            h1, .product-category-hero-image-header {
                margin-bottom: 10px;
            }
        }
        h2 {
            margin: 0 0 32px 34px;
            font-size: 18px;
        }
        .shop-button {
            max-width: calc(100% - 56px);
            margin-left: 32px;
            a {
                font-size: 13px;
                padding: 15px 20px;
                line-height: 20px;
                border: 1px solid #fff;
                background: $white;
                color: #231f20;
                &:after {
                }
            }
        }
        .bottom-navigation-row {
            height: 50px;
            background: rgba(0,0,0,0.75);
            position: absolute;
            bottom: 0;
            width: 100%;
            margin: 0;
            ul {
                li {
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    a {
                        background: transparent;
                        display: none;
                        line-height: 50px;
                        &.active {
                            display: block;
                            border: 0;

                        }
                    }
                }
            }
        }
        .slide-navigation {
            position: absolute;
            bottom: 10px;
            width: 100%;
            margin: 0;
            .left {
                margin-left: 14px;
            }
            .right {
                float: right;
                margin-right: 14px;
            }
        }
        .down-arrow {
            margin-left: 34px;
        }
    }
    #header.full-height {
        &.subheader {
            padding-top: 0;

            h1, .product-category-hero-image-header {
                padding-top: 48px;
            }
        }
        h2 {
            padding-right: 40px;
        }
    }
    #header.reduced-height {
        min-height: 80px;
        a.logo {
            margin: 28px 16px;
            background: transparent;
            img {
                height: 24px;
            }
        }
        .column:last-child {
            padding-top: 10px;
            padding-right: 18px;

        }
        .cart-link {
            position: relative;
            top: 3px;
            padding: 5px 6px;
        }
        .cart-link + span {
          margin: 0 14px;
        }
        .navigation-toggle, #search-toggle {
          position: relative;
          top: 4px;
          padding: 5px 2px;
          cursor: pointer;
          margin: 0;
        }
    }
}
@include queryWidth(max, 768px) {
    #mobile-navigation {
        height: calc(100% - 80px);
        top: 113px;
        .links {
            display: block;
            float: left;
            width: 100%;
            border-bottom: 1px solid #62666f;
            .columns {
                padding: 0 0 30px 32px;
                border: 0;
                margin-top: 0;
                &:first-child {
                    border: 0;
                    padding-bottom: 0;
                }
                ul {
                    li {
                        margin-top: 6px;
                        a {
                            font-size: 15px;
                        }
                        ul {
                            li {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
        .social-links {
            display: block;
            float: left;
            width: 100%;
            padding: 20px 0 20px 32px;
        }
    }
}
@include queryWidth(max, 560px) {
    .top-navigation-row, #mobile-header-copy {
        .column:last-child {
            span.location-header-text {
                display: none;
            }
            .visit-showroom:before {
                display: none;
            }
        }
    }
}
@include queryWidth(max, 400px) {
    #header.reduced-height, #header.full-height.home-header {
        //height: 418px;
        #home-header-backgrounds {
            height: 420px;
            div.home-header-background {
                height: 420px;
            }
        }
        .shop-button-holder {
            margin-top: 70px;
        }
        .hero-image-header {
            padding-right: 0;
        }
    }
}

